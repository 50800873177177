:root {
  --body-horizontal-padding: 4vw;
  --section-padding-top: 7.2rem;
}

@media only screen and (max-width: 64em) {
  :root {
    --body-horizontal-padding: 3vw;
  }
}

@media only screen and (max-width: 36.25em) {
  :root {
    --section-padding-top: 5.6rem;
  }
}

@media only screen and (max-width: 27.5em) {
  :root {
    --body-horizontal-padding: 4vw;
    --section-padding-top: 4.4rem;
  }
}

@font-face {
  font-family: ranade;
  src: url("Ranade-Bold.a9d1eea7.woff2") format("woff2"), url("Ranade-Bold.ad9401da.woff") format("woff"), url("Ranade-Bold.2aaf5925.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: ranade;
  src: url("Ranade-MediumItalic.046a1730.woff2") format("woff2"), url("Ranade-MediumItalic.fdedb4e6.woff") format("woff"), url("Ranade-MediumItalic.e771c77b.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: ranade;
  src: url("Ranade-Light.3b385730.woff2") format("woff2"), url("Ranade-Light.0e6ab78e.woff") format("woff"), url("Ranade-Light.0d9a3fcf.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: ranade;
  src: url("Ranade-Regular.36172446.woff2") format("woff2"), url("Ranade-Regular.f473a23f.woff") format("woff"), url("Ranade-Regular.11672e8d.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: ranade;
  src: url("Ranade-BoldItalic.4b9e5128.woff2") format("woff2"), url("Ranade-BoldItalic.ea23dbb6.woff") format("woff"), url("Ranade-BoldItalic.d32ddf26.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: barlow;
  src: url("barlow-medium-webfont.979fe26e.woff2") format("woff2"), url("barlow-medium-webfont.2269f0b8.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: barlow;
  src: url("barlow-bold-webfont.8c6c1e2b.woff2") format("woff2"), url("barlow-bold-webfont.4f2777aa.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: barlow;
  src: url("barlow-bolditalic-webfont.12d76361.woff2") format("woff2"), url("barlow-bolditalic-webfont.a87d9552.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: barlow;
  src: url("barlow-regular-webfont.cffb8583.woff2") format("woff2"), url("barlow-regular-webfont.e049619f.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: barlow;
  src: url("barlow-light-webfont.9fb957e1.woff2") format("woff2"), url("barlow-light-webfont.c0e83944.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: barlow;
  src: url("barlow-semibold-webfont.c8a57d2b.woff2") format("woff2"), url("barlow-semibold-webfont.d73771e8.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: barlow;
  src: url("barlow-italic-webfont.b395ccc9.woff2") format("woff2"), url("barlow-italic-webfont.1ff97a9b.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

.section-title, .heading-primary {
  text-rendering: geometricprecision;
  font-family: ranade;
  font-weight: 700;
}

.img__text, .btn, .menu__item, .paragraph {
  text-rendering: geometricprecision;
  font-family: barlow;
  font-size: 1.8rem;
  font-weight: 400;
}

@media only screen and (max-width: 64em) {
  .img__text, .btn, .menu__item, .paragraph {
    font-size: 1.7rem;
  }
}

@media only screen and (max-width: 60em) {
  .img__text, .btn, .menu__item, .paragraph {
    font-size: 1.6rem;
  }
}

.btn-2 {
  letter-spacing: .2px;
  text-rendering: geometricprecision;
  font-family: barlow;
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 1.9rem;
}

@media only screen and (max-width: 64em) {
  .btn-2 {
    font-size: 1.6rem;
  }
}

.about__row-4 .row__container .grigny-la-grande-borne__legende {
  letter-spacing: .2px;
  text-transform: uppercase;
  color: #898989;
  font-family: barlow;
  font-size: 1.4rem;
  font-weight: 600;
}

.heading-primary {
  letter-spacing: -2px;
  margin-bottom: 3.2rem;
  font-size: 4.8rem;
  line-height: 130%;
}

@media only screen and (max-width: 73.125em) {
  .heading-primary {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 60em) {
  .heading-primary {
    font-size: 3.6rem;
  }
}

@media only screen and (max-width: 27.5em) {
  .heading-primary {
    font-size: 3.4rem;
  }
}

.section-title {
  letter-spacing: -2px;
  word-break: break-word;
  border-bottom: 2px solid #222;
  align-items: start;
  padding-bottom: 3rem;
  font-size: 6.4rem;
  line-height: 1;
  display: flex;
}

@media only screen and (max-width: 73.125em) {
  .section-title {
    font-size: 5.6rem;
  }
}

@media only screen and (max-width: 51.25em) {
  .section-title {
    font-size: 5rem;
    line-height: 6rem;
  }
}

@media only screen and (max-width: 30em) {
  .section-title {
    padding-bottom: 2.4rem;
    font-size: 4.6rem;
    line-height: 5.4rem;
  }
}

@media only screen and (max-width: 27.5em) {
  .section-title {
    padding-bottom: 2rem;
    font-size: 4.2rem;
    line-height: 5rem;
  }
}

@media only screen and (max-width: 25.625em) {
  .section-title {
    padding-bottom: 1.8rem;
    font-size: 4rem;
    line-height: 4.6rem;
  }
}

@media only screen and (max-width: 23.75em) {
  .section-title {
    font-size: 3.8rem;
    line-height: 4.8rem;
  }
}

.section-title span {
  display: inline-block;
}

.section-title:before {
  content: "";
  border: 1px solid #222;
  border-radius: 50%;
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  margin-top: 2.4rem;
  margin-right: 3.2rem;
  display: inline-block;
}

@media only screen and (max-width: 73.125em) {
  .section-title:before {
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 51.25em) {
  .section-title:before {
    margin-top: 1.6rem;
  }
}

@media only screen and (max-width: 30em) {
  .section-title:before {
    width: 2.6rem;
    height: 2.6rem;
    margin-top: 1.5rem;
    margin-right: 2.6rem;
  }
}

@media only screen and (max-width: 25.625em) {
  .section-title:before {
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 2.4rem;
  }
}

@media only screen and (max-width: 23.75em) {
  .section-title:before {
    margin-top: 1.4rem;
    margin-right: 2rem;
  }
}

.paragraph {
  letter-spacing: 1px;
  line-height: 180%;
}

.coupdepouce {
  font-style: italic;
  font-weight: 700;
}

.quote {
  letter-spacing: -.5px;
  text-align: center;
  text-rendering: geometricprecision;
  font-family: ranade;
  font-size: 3.6rem;
  font-weight: 300;
  line-height: 130%;
}

@media only screen and (max-width: 64em) {
  .quote {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 60em) {
  .quote {
    font-size: 2.8rem;
  }
}

.quote .coupdepouce {
  font-weight: 500;
}

.bold-allcaps {
  text-transform: uppercase;
  font-weight: 700;
}

* {
  margin: 0;
  padding: 0;
}

*, :before, :after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  color: #222;
  font-size: 62.5%;
}

body {
  background-color: #fbfbfb;
}

body::-webkit-scrollbar {
  width: 0 !important;
}

a {
  color: currentColor;
  text-decoration: none;
}

ul {
  list-style: none;
}

picture {
  display: block;
}

img {
  border-radius: .8rem;
  display: block;
}

input {
  border: none;
  outline: none;
}

button {
  cursor: pointer;
  background: none;
  border: none;
}

.infos, .collecte, .about {
  padding-top: var(--section-padding-top);
}

.about__objectifs, .row-type--1, .section-title__wrapper, .footer, .collecte__row-6, .collecte__row-5, .collecte__row-4, .collecte__row-3, .collecte__row-2, .collecte__row-1, .about__row-11, .about__row-10, .about__row-9, .about__row-8, .about__row-7, .about__row-6, .about__row-4, .about__row-3, .about__row-1, .cta, .header {
  padding: 0 var(--body-horizontal-padding);
}

.about__objectifs .row__container, .about__objectifs .heading-wrapper, .row-type--1 .row__container, .footer .footer-content, .infos__content, .collecte__row-6 .row__container, .collecte__row-5 .row__container, .collecte__row-4 .row__container, .collecte__row-3 .row__container, .collecte__row-2 .row__container, .collecte__row-1 .row__container, .about__row-11 .row__container, .about__row-10 .row__container, .about__row-9 .row__container, .about__row-8 .row__container, .about__row-7 .row__container, .about__row-6 .row__container, .about__row-4 .row__container, .about__row-3 .row__container, .about__row-1 .row__container, .cta__container, .header__container, .section-title {
  max-width: 128rem;
  margin: 0 auto;
}

.about__objectifs .row__container, .row-type--1 .row__container, .collecte__row-6 .row__container, .collecte__row-5 .row__container, .collecte__row-4 .row__container, .collecte__row-3 .row__container, .collecte__row-2 .row__container, .about__row-11 .row__container, .about__row-9 .row__container, .about__row-7 .row__container, .about__row-6 .row__container, .about__row-3 .row__container, .about__row-1 .row__container, .cta__container {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;
  column-gap: 2.4rem;
  display: grid;
}

.collecte__row-5 .left, .collecte__row-4 .right, .collecte__row-4 .left, .collecte__row-3 .right, .about__row-9 .left, .about__row-7 .right, .about__row-7 .left, .about__row-6 .right, .about__row-3 .right, .about__row-1 .left, .cta .left {
  place-content: start center;
  place-items: start;
  display: grid;
}

@media only screen and (max-width: 64em) {
  .nav-open {
    height: 100vh;
    overflow: hidden;
  }
}

.header {
  z-index: 100;
  background-color: #fff;
  width: 100%;
  transition: all .2s ease-in-out;
  position: fixed;
  top: 0;
}

.scroll-down .header {
  transform: translateY(-100%);
}

.header__container {
  justify-content: space-between;
  align-items: center;
  column-gap: 5.6rem;
  padding: 2.4rem 0;
  display: flex;
}

@media only screen and (max-width: 64em) {
  .header__container {
    column-gap: 4.8rem;
  }
}

@media only screen and (max-width: 32.5em) {
  .header__container {
    column-gap: 3.2rem;
    padding: 1.6rem 0;
  }
}

@media only screen and (max-width: 23.75em) {
  .header__container {
    column-gap: 2.4rem;
  }
}

.header__logo {
  fill: #222;
  z-index: 11;
  width: 8.5rem;
  height: 5rem;
  display: block;
}

@media only screen and (max-width: 32.5em) {
  .header__logo {
    width: 6.8rem;
    height: 4rem;
  }
}

.header__menu {
  align-self: stretch;
  margin-left: auto;
}

@media only screen and (max-width: 64em) {
  .header__menu {
    z-index: 10;
    visibility: hidden;
    background-color: #f0edfd;
    width: 100%;
    height: calc(100vh - 9.8rem);
    position: fixed;
    top: 9.8rem;
    right: 0;
    overflow-x: hidden;
    transform: translateX(100%);
  }

  .header__menu.with-transition {
    transition: transform .2s ease-in-out, visibility .2s ease-in-out;
  }

  .nav-open .header__menu {
    visibility: visible;
    display: initial;
    transition: transform .2s ease-in-out, visibility .2s ease-in-out;
    transform: translateX(0%);
  }
}

@media only screen and (max-width: 32.5em) {
  .header__menu {
    height: calc(100vh - 7.2rem);
    top: 7.2rem;
  }
}

.header__icons {
  align-items: center;
  column-gap: 1.6rem;
  display: flex;
}

@media only screen and (max-width: 64em) {
  .header__icons {
    margin-left: auto;
  }
}

.header__icon {
  background-color: #c0f6ec;
  border-radius: .4rem;
  justify-content: center;
  align-items: center;
  width: 3.2rem;
  height: 3.2rem;
  transition: all .3s ease-in-out;
  display: flex;
}

@media (hover: hover) {
  .header__icon:hover {
    background-color: #93ecda;
    transform: translateY(-4px);
  }
}

@media only screen and (max-width: 64em) {
  .header__icon {
    width: 3.8rem;
    height: 3.8rem;
  }
}

.header__facebook {
  fill: #222;
  width: 1.6rem;
  height: 1.6rem;
}

@media only screen and (max-width: 64em) {
  .header__facebook {
    width: 2rem;
    height: 2rem;
  }
}

.header .mobile-nav__btn {
  display: none;
}

@media only screen and (max-width: 64em) {
  .header .mobile-nav__btn {
    z-index: 11;
    display: block;
  }
}

@media only screen and (max-width: 32.5em) {
  .header .mobile-nav__btn {
    margin-right: 1rem;
  }
}

.header .mobile-nav__icon {
  color: #222;
  width: 3rem;
  height: 3rem;
  transition: all .3s ease-in-out;
}

@media (hover: hover) {
  .header .mobile-nav__icon:hover {
    transform: scale(1.15);
  }
}

.header .mobile-nav__icon--close {
  display: none;
}

.nav-open .header .mobile-nav__icon--close {
  display: block;
}

.nav-open .header .mobile-nav__icon--open {
  display: none;
}

.menu {
  letter-spacing: .3px;
  align-items: stretch;
  column-gap: 5.6rem;
  height: 100%;
  display: flex;
}

@media only screen and (max-width: 64em) {
  .menu {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 8rem;
    margin-top: 12rem;
    margin-left: 3.6rem;
  }
}

@media only screen and (max-width: 25.625em) {
  .menu {
    margin-left: 2rem;
  }
}

.menu__item {
  align-items: stretch;
  font-size: 1.8rem;
  font-weight: 400;
  display: flex;
}

.menu__item .main-nav-link {
  align-items: center;
  height: 100%;
  display: flex;
  position: relative;
}

.menu__item .main-nav-link:after {
  content: "";
  background-color: #93ecda;
  width: 0;
  height: 2px;
  transition: all .25s ease-in-out;
  display: block;
  position: absolute;
  bottom: 3px;
  left: 0;
}

.menu__item .main-nav-link:hover:after {
  width: 100%;
}

@media only screen and (max-width: 64em) {
  .menu__item {
    font-size: 3.6rem;
    font-weight: 400;
  }

  .menu__item:before {
    content: ".";
    margin-right: 2rem;
  }
}

@media only screen and (max-width: 64em) and (max-width: 27.5em) {
  .menu__item:before {
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 32.5em) {
  .menu__item {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 25.625em) {
  .menu__item {
    font-size: 2.8rem;
  }
}

main {
  margin-top: 9.8rem;
}

@media only screen and (max-width: 32.5em) {
  main {
    margin-top: 7.2rem;
  }
}

.cta {
  background-color: #fbfbfb;
  margin-bottom: 6rem;
  padding-top: 7rem;
  padding-bottom: 11rem;
  position: relative;
}

@media only screen and (max-width: 36.25em) {
  .cta {
    margin-bottom: 3.4rem;
  }
}

.cta .illustration {
  filter: brightness(1.15);
  width: 20rem;
  position: absolute;
  bottom: 0;
  left: 40%;
  transform: rotate(-12deg);
}

.cta .illustration img {
  width: 100%;
}

.cta .bg {
  background-color: #f0edfd;
  border-radius: 0 .8rem .8rem 0;
  width: 55%;
  height: 95%;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 51.25em) {
  .cta .bg {
    width: 80%;
    height: 50%;
  }
}

@media only screen and (max-width: 38.75em) {
  .cta .bg {
    width: 90%;
  }
}

@media only screen and (max-width: 36.25em) {
  .cta .bg {
    height: 55%;
  }
}

@media only screen and (max-width: 27.5em) {
  .cta .bg {
    border-radius: 0;
    width: 100%;
    height: 60%;
  }
}

@media only screen and (max-width: 25.625em) {
  .cta .bg {
    height: 60%;
  }
}

@media only screen and (max-width: 51.25em) {
  .cta__container {
    grid-template-rows: repeat(2, min-content);
    grid-template-columns: 1fr;
    row-gap: 6rem;
  }
}

@media only screen and (max-width: 36.25em) {
  .cta__container {
    row-gap: 5rem;
  }
}

@media only screen and (max-width: 25.625em) {
  .cta__container {
    row-gap: 7rem;
  }
}

.cta .left {
  border-radius: 2.4rem;
  grid-template-rows: repeat(2, min-content) min-content;
  align-content: center;
  align-self: stretch;
  padding: 7.2rem min(11.4rem, 7vw);
  position: relative;
}

@media only screen and (max-width: 60em) {
  .cta .left {
    padding: 7.2rem 4vw;
  }
}

@media only screen and (max-width: 51.25em) {
  .cta .left {
    width: 90%;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 38.75em) {
  .cta .left {
    width: 70%;
  }
}

@media only screen and (max-width: 36.25em) {
  .cta .left {
    width: 85%;
  }
}

@media only screen and (max-width: 32.5em) {
  .cta .left {
    width: 95%;
  }
}

@media only screen and (max-width: 25.625em) {
  .cta .left {
    width: 100%;
  }
}

.cta .left .heading-primary {
  color: #3500fa;
  margin-bottom: 0;
  font-size: 6.4rem;
  line-height: 6.4rem;
}

@media only screen and (max-width: 73.125em) {
  .cta .left .heading-primary {
    font-size: 5.6rem;
  }
}

@media only screen and (max-width: 60em) {
  .cta .left .heading-primary {
    font-size: 5rem;
  }
}

@media only screen and (max-width: 51.25em) {
  .cta .left .heading-primary {
    width: 70%;
  }

  .cta .left .heading-primary span {
    display: inline-block;
  }
}

@media only screen and (max-width: 38.75em) {
  .cta .left .heading-primary {
    width: 100%;
  }
}

@media only screen and (max-width: 30em) {
  .cta .left .heading-primary {
    font-size: 4.8rem;
  }
}

@media only screen and (max-width: 27.5em) {
  .cta .left .heading-primary {
    width: 100%;
    font-size: 4.6rem;
    line-height: 5.4rem;
  }
}

@media only screen and (max-width: 25.625em) {
  .cta .left .heading-primary {
    font-size: 4.4rem;
    line-height: 5.4rem;
  }
}

@media only screen and (max-width: 23.75em) {
  .cta .left .heading-primary {
    font-size: 4.2rem;
  }
}

.cta .left .heading-primary:after {
  content: "";
  background-color: #3500fa;
  width: 6rem;
  height: 2px;
  margin: 3rem 0;
  display: block;
}

@media only screen and (max-width: 27.5em) {
  .cta .left .heading-primary:after {
    width: 5rem;
  }
}

.cta .left .paragraph {
  margin-top: -.4rem;
  margin-bottom: 5.2rem;
}

@media only screen and (max-width: 51.25em) {
  .cta .left .paragraph {
    width: 60%;
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 38.75em) {
  .cta .left .paragraph {
    width: 100%;
  }
}

@media only screen and (max-width: 36.25em) {
  .cta .left .paragraph {
    width: 80%;
  }
}

@media only screen and (max-width: 32.5em) {
  .cta .left .paragraph {
    width: 75%;
  }
}

@media only screen and (max-width: 25.625em) {
  .cta .left .paragraph {
    width: 100%;
  }
}

.cta .left .btn {
  color: #3500fa;
  align-self: end;
}

.cta .left .circle {
  background-color: #00ebbf;
  border-radius: 50%;
  width: 3.4rem;
  height: 3.4rem;
  display: block;
  position: absolute;
  top: 8%;
  right: 8%;
}

.cta .left .triangle {
  width: 3.5rem;
  height: 3.2rem;
  position: absolute;
  bottom: 16%;
  right: 12%;
}

.cta .left .rectangle {
  background: #f05ce0;
  border-radius: .4rem;
  width: 2.4rem;
  height: 4rem;
  position: absolute;
  bottom: 25%;
  left: 9%;
  transform: rotate(165deg);
}

.cta .right {
  z-index: 2;
  border-radius: 2.4rem;
  overflow: hidden;
}

@media only screen and (max-width: 64em) {
  .cta .right {
    align-self: stretch;
  }
}

@media only screen and (max-width: 51.25em) {
  .cta .right {
    place-self: start end;
    width: 80%;
  }
}

@media only screen and (max-width: 38.75em) {
  .cta .right {
    justify-self: end;
    width: 90%;
    padding: 0;
  }
}

@media only screen and (max-width: 30em) {
  .cta .right {
    border-radius: 1.6rem;
  }
}

@media only screen and (max-width: 25.625em) {
  .cta .right {
    width: 100%;
  }
}

.cta .right img {
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 2.4rem;
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 73.125em) {
  .cta .right img {
    scale: 1.1;
  }
}

@media only screen and (max-width: 64em) {
  .cta .right img {
    object-position: 40% 100%;
    width: 100%;
    height: 100%;
    scale: 1;
  }
}

@media only screen and (max-width: 51.25em) {
  .cta .right img {
    object-position: 50% 20%;
  }
}

@media only screen and (max-width: 36.25em) {
  .cta .right img {
    scale: 1.2;
  }
}

@media only screen and (max-width: 32.5em) {
  .cta .right img {
    aspect-ratio: 1;
    scale: 1.3;
  }
}

@media only screen and (max-width: 51.25em) {
  .about__row-1 .row__container {
    grid-template-rows: repeat(2, min-content);
    grid-template-columns: 1fr;
    gap: 6rem 0;
  }
}

.about__row-1 .left {
  padding: 0 min(11.4rem, 7vw);
}

@media only screen and (max-width: 60em) {
  .about__row-1 .left {
    padding: 0 7vw 0 0;
  }
}

@media only screen and (max-width: 51.25em) {
  .about__row-1 .left {
    width: 70%;
    padding: 0 4vw;
  }
}

@media only screen and (max-width: 38.75em) {
  .about__row-1 .left {
    width: 75%;
  }
}

@media only screen and (max-width: 25.625em) {
  .about__row-1 .left {
    width: 100%;
  }
}

.about__row-1 .right {
  justify-self: end;
  justify-items: start;
  width: 90%;
  display: grid;
}

@media only screen and (max-width: 80em) {
  .about__row-1 .right {
    width: 100%;
  }
}

@media only screen and (max-width: 51.25em) {
  .about__row-1 .right {
    grid-row: 2;
    justify-self: end;
    width: 75%;
    padding-right: 4vw;
  }
}

@media only screen and (max-width: 38.75em) {
  .about__row-1 .right {
    justify-self: end;
    width: 85%;
    padding: 0;
  }
}

@media only screen and (max-width: 27.5em) {
  .about__row-1 .right {
    width: 100%;
  }
}

.about__row-1 .right img {
  aspect-ratio: 1.33;
  object-fit: cover;
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 60em) {
  .about__row-1 .right img {
    object-position: 70% center;
    height: 100%;
  }
}

.about__row-2 {
  padding: 12rem 0;
}

.about__logo-2 {
  width: 52.2rem;
  height: 52.2rem;
  margin: 0 auto;
}

@media only screen and (max-width: 36.25em) {
  .about__logo-2 {
    width: 44rem;
    height: 44rem;
  }
}

@media only screen and (max-width: 30em) {
  .about__logo-2 {
    width: 38rem;
    height: 38rem;
  }
}

@media only screen and (max-width: 27.5em) {
  .about__logo-2 {
    width: 35rem;
    height: 35rem;
  }
}

@media only screen and (max-width: 23.75em) {
  .about__logo-2 {
    width: 32rem;
    height: 32rem;
  }
}

.about__logo-2 img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 10px #00000008;
}

.about__row-3 .row__container {
  align-items: end;
}

@media only screen and (max-width: 51.25em) {
  .about__row-3 .row__container {
    grid-template-rows: repeat(2, min-content);
    grid-template-columns: 1fr;
    gap: 6rem 0;
  }

  .about__row-3 .left {
    width: 70%;
    padding: 0 4vw;
  }
}

@media only screen and (max-width: 38.75em) {
  .about__row-3 .left {
    width: 75%;
  }
}

@media only screen and (max-width: 30em) {
  .about__row-3 .left {
    width: 80%;
  }
}

@media only screen and (max-width: 27.5em) {
  .about__row-3 .left {
    width: 100%;
  }
}

@media only screen and (max-width: 25.625em) {
  .about__row-3 .left {
    width: 100%;
  }
}

.about__row-3 .left .heading-primary {
  margin-bottom: 0;
}

.about__row-3 .left .btn-2 {
  margin-top: 2.4rem;
}

.about__row-3 .right {
  padding-left: min(22.8rem, 14vw);
}

.about__row-3 .right .paragraph {
  margin-bottom: 2.4rem;
}

@media only screen and (max-width: 60em) {
  .about__row-3 .right {
    padding-left: 7vw;
  }
}

@media only screen and (max-width: 51.25em) {
  .about__row-3 .right {
    justify-self: end;
    width: 75%;
    padding-left: 0;
    padding-right: 4vw;
  }
}

@media only screen and (max-width: 25.625em) {
  .about__row-3 .right {
    width: 100%;
    padding: 0 4vw;
  }
}

.about__row-4 {
  background-color: #f5f5f7;
  margin-top: 14.4rem;
  padding-top: 10rem;
  padding-bottom: 10rem;
}

@media only screen and (max-width: 27.5em) {
  .about__row-4 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

.about__row-4 .row__container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.about__row-4 .row__container .quote {
  width: 90%;
  margin-bottom: 2.4rem;
}

@media only screen and (max-width: 27.5em) {
  .about__row-4 .row__container .quote {
    margin-bottom: 4.8rem;
  }
}

@media only screen and (max-width: 25.625em) {
  .about__row-4 .row__container .quote {
    width: 100%;
  }
}

.about__row-4 .row__container .grigny-la-grande-borne {
  width: 80%;
  margin-top: 5.6rem;
  position: relative;
}

@media only screen and (max-width: 48.75em) {
  .about__row-4 .row__container .grigny-la-grande-borne {
    min-width: 80%;
  }
}

@media only screen and (max-width: 30em) {
  .about__row-4 .row__container .grigny-la-grande-borne {
    min-width: 90%;
  }
}

@media only screen and (max-width: 25.625em) {
  .about__row-4 .row__container .grigny-la-grande-borne {
    width: 100%;
  }
}

.about__row-4 .row__container .grigny-la-grande-borne img {
  aspect-ratio: 16 / 9;
  object-fit: cover;
  width: 100%;
}

.about__row-4 .row__container .grigny-la-grande-borne__legende {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(200%);
}

@media only screen and (max-width: 38.75em) {
  .about__row-4 .row__container .grigny-la-grande-borne__legende {
    bottom: -10%;
  }
}

.about__row-6 {
  margin-top: 12rem;
}

@media only screen and (max-width: 51.25em) {
  .about__row-6 .row__container {
    grid-template-rows: repeat(2, min-content);
    grid-template-columns: 1fr;
    gap: 6rem 0;
  }
}

.about__row-6 .img-container {
  width: 90%;
}

@media only screen and (max-width: 60em) {
  .about__row-6 .img-container {
    width: 100%;
  }
}

@media only screen and (max-width: 51.25em) {
  .about__row-6 .img-container {
    grid-row: 2;
    justify-self: end;
    width: 75%;
    padding-right: 4vw;
  }
}

@media only screen and (max-width: 38.75em) {
  .about__row-6 .img-container {
    width: 80%;
  }
}

@media only screen and (max-width: 30em) {
  .about__row-6 .img-container {
    padding: 0;
  }
}

@media only screen and (max-width: 25.625em) {
  .about__row-6 .img-container {
    width: 100%;
  }
}

.about__row-6 .img-container img {
  aspect-ratio: 1;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.about__row-6 .right {
  padding: 0 min(11.4rem, 7vw);
}

@media only screen and (max-width: 60em) {
  .about__row-6 .right {
    padding: 0 0 0 7vw;
  }
}

@media only screen and (max-width: 51.25em) {
  .about__row-6 .right {
    grid-row: 1;
    width: 70%;
    padding: 0 4vw;
  }
}

@media only screen and (max-width: 38.75em) {
  .about__row-6 .right {
    width: 75%;
  }
}

@media only screen and (max-width: 27.5em) {
  .about__row-6 .right {
    width: 80%;
  }
}

@media only screen and (max-width: 25.625em) {
  .about__row-6 .right {
    width: 100%;
  }
}

.about__row-7 {
  margin-top: 12rem;
}

@media only screen and (max-width: 51.25em) {
  .about__row-7 .row__container {
    grid-template-rows: repeat(2, min-content);
    grid-template-columns: 1fr;
    gap: 6rem 0;
  }
}

@media only screen and (max-width: 25.625em) {
  .about__row-7 .row__container {
    row-gap: 3.2rem;
  }
}

.about__row-7 .left {
  padding: 0 min(11.4rem, 7vw);
}

@media only screen and (max-width: 60em) {
  .about__row-7 .left {
    padding: 0 7vw 0 0;
  }
}

@media only screen and (max-width: 51.25em) {
  .about__row-7 .left {
    grid-row: 2;
    justify-self: end;
    width: 75%;
    padding-right: 4vw;
  }
}

@media only screen and (max-width: 25.625em) {
  .about__row-7 .left {
    width: 100%;
    padding: 0 4vw;
  }
}

.about__row-7 .right {
  padding: 0 min(11.4rem, 7vw);
}

@media only screen and (max-width: 60em) {
  .about__row-7 .right {
    padding: 0 7vw 0 0;
  }
}

@media only screen and (max-width: 51.25em) {
  .about__row-7 .right {
    grid-row: 1;
    width: 70%;
    padding: 0 4vw;
  }
}

@media only screen and (max-width: 38.75em) {
  .about__row-7 .right {
    width: 75%;
  }
}

@media only screen and (max-width: 27.5em) {
  .about__row-7 .right {
    width: 80%;
  }
}

@media only screen and (max-width: 25.625em) {
  .about__row-7 .right {
    width: 100%;
    padding: 0 4vw;
  }
}

.about__row-7 .right .heading-primary {
  margin-bottom: 0;
}

.about__row-8 {
  margin-top: 14.4rem;
}

@media only screen and (max-width: 27.5em) {
  .about__row-8 {
    margin-top: 10rem;
  }
}

@media only screen and (max-width: 23.75em) {
  .about__row-8 {
    margin-top: 8rem;
  }
}

.about__row-8 .row__container {
  background-color: #f0edfd;
  border-radius: 2.4rem;
  padding: 10rem 0;
}

@media only screen and (max-width: 80em) {
  .about__row-8 .row__container {
    padding: min(10rem, 5vw) 3vw;
  }
}

@media only screen and (max-width: 38.75em) {
  .about__row-8 .row__container {
    padding: min(10rem, 7vw) 3vw;
  }
}

.about__row-8 .quote {
  color: #222;
  width: 90%;
  margin: 0 auto;
  font-weight: 400;
  line-height: 160%;
}

.about__row-8 .quote span {
  color: #222;
  font-size: 4.8rem;
  font-weight: 700;
}

@media only screen and (max-width: 30em) {
  .about__row-8 .quote span {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 23.75em) {
  .about__row-8 .quote span {
    font-size: 3.8rem;
  }
}

.about__row-9 {
  margin-top: 14.4rem;
}

@media only screen and (max-width: 51.25em) {
  .about__row-9 .row__container {
    grid-template-rows: repeat(2, min-content);
    grid-template-columns: 1fr;
    gap: 6rem 0;
  }
}

.about__row-9 .left {
  padding: 0 min(11.4rem, 7vw);
}

@media only screen and (max-width: 60em) {
  .about__row-9 .left {
    padding: 0 7vw 0 0;
  }
}

@media only screen and (max-width: 51.25em) {
  .about__row-9 .left {
    grid-row: 1;
    width: 70%;
    padding: 0 4vw;
  }
}

@media only screen and (max-width: 38.75em) {
  .about__row-9 .left {
    width: 75%;
  }
}

@media only screen and (max-width: 27.5em) {
  .about__row-9 .left {
    width: 80%;
  }
}

@media only screen and (max-width: 25.625em) {
  .about__row-9 .left {
    width: 100%;
  }
}

.about__row-9 .img-container {
  width: 90%;
}

@media only screen and (max-width: 60em) {
  .about__row-9 .img-container {
    width: 100%;
  }
}

@media only screen and (max-width: 51.25em) {
  .about__row-9 .img-container {
    grid-row: 2;
    justify-self: end;
    width: 75%;
    padding-right: 4vw;
  }
}

@media only screen and (max-width: 38.75em) {
  .about__row-9 .img-container {
    width: 80%;
  }
}

@media only screen and (max-width: 30em) {
  .about__row-9 .img-container {
    padding: 0;
  }
}

@media only screen and (max-width: 25.625em) {
  .about__row-9 .img-container {
    width: 100%;
  }
}

.about__row-9 .img-container img {
  aspect-ratio: 1;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.about__row-10 {
  margin-top: 20rem;
}

@media only screen and (max-width: 25.625em) {
  .about__row-10 {
    margin-top: 16rem;
  }
}

.about__row-10 .row__container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.about__row-10 .circle {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: inline-block;
}

.about__row-10 .circle:first-child {
  background-color: #e6e6e6;
  margin-bottom: 6rem;
}

.about__row-10 .circle:last-child {
  background-color: #e6e6e6;
  margin-top: 6rem;
}

.about__row-10 .quote {
  text-align: start;
  width: 50%;
  margin: 0 auto;
  font-size: 5.6rem;
  font-weight: 400;
}

@media only screen and (max-width: 73.125em) {
  .about__row-10 .quote {
    width: 60%;
  }
}

@media only screen and (max-width: 64em) {
  .about__row-10 .quote {
    font-size: 4.8rem;
  }
}

@media only screen and (max-width: 60em) {
  .about__row-10 .quote {
    width: 70%;
  }
}

@media only screen and (max-width: 38.75em) {
  .about__row-10 .quote {
    width: 80%;
    font-size: 4.4rem;
  }
}

@media only screen and (max-width: 30em) {
  .about__row-10 .quote {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 25.625em) {
  .about__row-10 .quote {
    font-size: 3.6rem;
  }
}

.about__row-10 .quote .coupdepouce, .about__row-10 span:last-of-type {
  font-weight: 700;
}

.about__row-11 {
  margin-top: 20rem;
  margin-bottom: 14.4rem;
}

@media only screen and (max-width: 25.625em) {
  .about__row-11 {
    margin-top: 16rem;
  }
}

.about__row-11 .row__container {
  align-items: start;
}

@media only screen and (max-width: 60em) {
  .about__row-11 .row__container {
    grid-template-columns: 1fr;
    row-gap: 3.2rem;
  }
}

.about__row-11 .heading-primary {
  margin-bottom: 4.8rem;
  font-size: 4rem;
}

.about__row-11 .card-left {
  color: #3500fa;
  background-color: #d7f9f3;
  border-radius: 2.4rem;
  padding: 4.8rem;
}

@media only screen and (max-width: 32.5em) {
  .about__row-11 .card-left {
    padding: 3.6rem;
  }
}

@media only screen and (max-width: 25.625em) {
  .about__row-11 .card-left {
    padding: 3rem;
  }
}

.about__row-11 .card-left span {
  margin-left: 2rem;
}

.about__row-11 .card-right {
  color: #3500fa;
  background-color: #f0edfd;
  border-radius: 2.4rem;
  padding: 4.8rem;
}

@media only screen and (max-width: 32.5em) {
  .about__row-11 .card-right {
    padding: 3.6rem;
  }
}

.collecte__row-1 .row__container {
  grid-template-rows: repeat(2, min-content);
  grid-template-columns: repeat(2, 1fr);
  align-items: end;
  column-gap: 6rem;
  display: grid;
}

@media only screen and (max-width: 51.25em) {
  .collecte__row-1 .row__container {
    column-gap: 4rem;
  }
}

@media only screen and (max-width: 48.75em) {
  .collecte__row-1 .row__container {
    grid-template-rows: repeat(2, min-content);
    grid-template-columns: 1fr;
    gap: 6rem 0;
  }
}

.collecte__row-1 .quote-wrapper {
  flex-direction: column;
  display: flex;
}

@media only screen and (max-width: 48.75em) {
  .collecte__row-1 .quote-wrapper:first-child {
    width: 70%;
    padding: 0 4vw;
  }
}

@media only screen and (max-width: 38.75em) {
  .collecte__row-1 .quote-wrapper:first-child {
    width: 75%;
  }
}

@media only screen and (max-width: 27.5em) {
  .collecte__row-1 .quote-wrapper:first-child {
    width: 85%;
  }
}

@media only screen and (max-width: 25.625em) {
  .collecte__row-1 .quote-wrapper:first-child {
    width: 100%;
  }
}

@media only screen and (max-width: 48.75em) {
  .collecte__row-1 .quote-wrapper:last-child {
    justify-self: end;
    width: 75%;
    padding-right: 4vw;
  }
}

@media only screen and (max-width: 27.5em) {
  .collecte__row-1 .quote-wrapper:last-child {
    width: 80%;
  }
}

@media only screen and (max-width: 25.625em) {
  .collecte__row-1 .quote-wrapper:last-child {
    width: 100%;
    padding: 0 4vw;
  }
}

.collecte__row-1 .quote {
  margin: 0 auto;
  padding: 0 5rem 5rem;
  display: inline-block;
}

@media only screen and (max-width: 60em) {
  .collecte__row-1 .quote {
    padding: 2.5rem;
  }
}

@media only screen and (max-width: 48.75em) {
  .collecte__row-1 .quote {
    padding: 0;
  }
}

.collecte__row-1 .quote:first-child, .collecte__row-1 .quote:last-child {
  text-align: left;
}

.collecte__row-2 {
  z-index: 1;
  margin-top: 12rem;
  position: relative;
  overflow: hidden;
}

.collecte__row-2 .row__container {
  background-color: #d7f9f3;
  border-radius: 2.4rem;
  padding: 10rem 0;
}

@media only screen and (max-width: 48.75em) {
  .collecte__row-2 .row__container {
    grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
  }
}

@media only screen and (max-width: 36.25em) {
  .collecte__row-2 .row__container {
    grid-template-rows: repeat(2, min-content);
    grid-template-columns: 1fr;
    gap: 6rem 0;
    padding: 6rem 0;
  }
}

.collecte__row-2 .heading-primary {
  margin-bottom: 0;
}

.collecte__row-2 .left {
  justify-content: center;
  display: flex;
}

@media only screen and (max-width: 36.25em) {
  .collecte__row-2 .left {
    grid-row: 2 / 3;
    justify-content: end;
    width: 75%;
    padding-left: 8vw;
  }
}

@media only screen and (max-width: 27.5em) {
  .collecte__row-2 .left {
    justify-content: end;
    width: 100%;
    padding-right: 8rem;
  }
}

.collecte__row-2 .right {
  padding: 0 min(11.4rem, 7vw);
}

@media only screen and (max-width: 38.75em) {
  .collecte__row-2 .right {
    padding-left: 0;
  }
}

@media only screen and (max-width: 36.25em) {
  .collecte__row-2 .right {
    grid-row: 1 / 2;
    width: 75%;
    padding-left: 4vw;
  }
}

@media only screen and (max-width: 27.5em) {
  .collecte__row-2 .right {
    width: 100%;
    padding: 0 4vw;
  }
}

.collecte__row-2 ul {
  letter-spacing: 1px;
  text-rendering: geometricprecision;
  text-transform: uppercase;
  font-family: barlow;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 180%;
}

@media only screen and (max-width: 30em) {
  .collecte__row-2 ul {
    display: inline-block;
  }
}

.collecte__row-2 ul li:not(:last-child) {
  margin-bottom: 4.8rem;
}

.collecte__row-2 ul li:last-child {
  text-transform: lowercase;
}

.collecte__row-3 {
  margin-top: 17rem;
}

@media only screen and (max-width: 51.25em) {
  .collecte__row-3 .row__container {
    grid-template-rows: repeat(2, min-content);
    grid-template-columns: 1fr;
    gap: 6rem 0;
  }
}

.collecte__row-3 .img-container {
  justify-self: end;
  width: 90%;
}

@media only screen and (max-width: 80em) {
  .collecte__row-3 .img-container {
    width: 100%;
  }
}

@media only screen and (max-width: 51.25em) {
  .collecte__row-3 .img-container {
    grid-row: 2;
    justify-self: end;
    width: 75%;
    padding-right: 4vw;
  }
}

@media only screen and (max-width: 38.75em) {
  .collecte__row-3 .img-container {
    width: 85%;
  }
}

@media only screen and (max-width: 32.5em) {
  .collecte__row-3 .img-container {
    width: 90%;
    padding: 0;
  }
}

@media only screen and (max-width: 27.5em) {
  .collecte__row-3 .img-container {
    width: 100%;
  }
}

.collecte__row-3 .img-container img {
  aspect-ratio: 1.33;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.collecte__row-3 .right {
  padding: 0 min(11.4rem, 7vw);
}

@media only screen and (max-width: 60em) {
  .collecte__row-3 .right {
    padding: 0 0 0 7vw;
  }
}

@media only screen and (max-width: 51.25em) {
  .collecte__row-3 .right {
    grid-row: 1;
    width: 70%;
    padding: 0 4vw;
  }
}

@media only screen and (max-width: 38.75em) {
  .collecte__row-3 .right {
    width: 75%;
  }
}

@media only screen and (max-width: 27.5em) {
  .collecte__row-3 .right {
    width: 80%;
  }
}

@media only screen and (max-width: 25.625em) {
  .collecte__row-3 .right {
    width: 100%;
  }
}

.collecte__row-4 {
  margin-top: 12rem;
}

.collecte__row-4 .row__container {
  padding-bottom: 6.4rem;
}

@media only screen and (max-width: 51.25em) {
  .collecte__row-4 .row__container {
    padding: 6.4rem var(--body-horizontal-padding);
    column-gap: 4rem;
  }
}

@media only screen and (max-width: 48.75em) {
  .collecte__row-4 .row__container {
    grid-template-rows: repeat(2, min-content);
    grid-template-columns: 1fr;
    gap: 6rem 0;
  }
}

.collecte__row-4 .heading-primary {
  margin-bottom: 0;
}

.collecte__row-4 .left {
  z-index: 2;
  padding: 0 min(11.4rem, 7vw);
}

@media only screen and (max-width: 60em) {
  .collecte__row-4 .left {
    padding: 0 7vw 0 0;
  }
}

@media only screen and (max-width: 51.25em) {
  .collecte__row-4 .left {
    padding: 0;
  }
}

@media only screen and (max-width: 48.75em) {
  .collecte__row-4 .left {
    justify-self: end;
    width: 75%;
    padding-right: 4vw;
  }
}

@media only screen and (max-width: 25.625em) {
  .collecte__row-4 .left {
    width: 100%;
    padding-right: 0;
  }
}

.collecte__row-4 .right {
  z-index: 2;
  padding: 0 min(11.4rem, 7vw);
}

@media only screen and (max-width: 60em) {
  .collecte__row-4 .right {
    padding: 0 0 0 7vw;
  }
}

@media only screen and (max-width: 51.25em) {
  .collecte__row-4 .right {
    padding: 0;
  }
}

@media only screen and (max-width: 48.75em) {
  .collecte__row-4 .right {
    grid-row: 1;
    width: 70%;
    padding: 0 4vw;
  }
}

@media only screen and (max-width: 38.75em) {
  .collecte__row-4 .right {
    width: 75%;
  }
}

@media only screen and (max-width: 27.5em) {
  .collecte__row-4 .right {
    width: 80%;
    padding: 0;
  }
}

@media only screen and (max-width: 25.625em) {
  .collecte__row-4 .right {
    width: 100%;
  }
}

.collecte__row-5 {
  margin-top: 12rem;
}

@media only screen and (max-width: 51.25em) {
  .collecte__row-5 .row__container {
    grid-template-rows: repeat(2, min-content);
    grid-template-columns: 1fr;
    gap: 6rem 0;
  }
}

.collecte__row-5 .heading-primary {
  margin-bottom: 0;
}

.collecte__row-5 .left {
  padding: 0 min(11.4rem, 7vw);
}

@media only screen and (max-width: 60em) {
  .collecte__row-5 .left {
    padding: 0 7vw 0 0;
  }
}

@media only screen and (max-width: 51.25em) {
  .collecte__row-5 .left {
    justify-self: end;
    width: 75%;
    padding-right: 4vw;
  }
}

@media only screen and (max-width: 27.5em) {
  .collecte__row-5 .left {
    width: 80%;
  }
}

.collecte__row-5 .img-container {
  width: 90%;
}

@media only screen and (max-width: 80em) {
  .collecte__row-5 .img-container {
    width: 100%;
  }
}

@media only screen and (max-width: 51.25em) {
  .collecte__row-5 .img-container {
    grid-row: 2;
    justify-self: start;
    width: 75%;
    padding-left: 4vw;
  }
}

@media only screen and (max-width: 38.75em) {
  .collecte__row-5 .img-container {
    width: 85%;
  }
}

@media only screen and (max-width: 32.5em) {
  .collecte__row-5 .img-container {
    width: 90%;
    padding: 0;
  }
}

.collecte__row-5 .img-container img {
  aspect-ratio: 1.33;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.collecte__row-6 {
  margin-top: 12rem;
  padding-top: 10rem;
  padding-bottom: 10rem;
  position: relative;
}

.collecte__row-6:before {
  content: "";
  z-index: -1;
  background-color: #f0edfd;
  border-radius: 0 2.4rem 2.4rem 0;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 51.25em) {
  .collecte__row-6:before {
    width: 60%;
  }
}

@media only screen and (max-width: 48.75em) {
  .collecte__row-6:before {
    width: 68%;
  }
}

@media only screen and (max-width: 38.75em) {
  .collecte__row-6:before {
    width: 80%;
  }
}

@media only screen and (max-width: 32.5em) {
  .collecte__row-6:before {
    width: 90%;
  }
}

@media only screen and (max-width: 25.625em) {
  .collecte__row-6:before {
    width: 95%;
  }
}

@media only screen and (max-width: 23.75em) {
  .collecte__row-6:before {
    border-radius: 0;
    width: 100%;
  }
}

.collecte__row-6 .row__container {
  color: #3500fa;
  grid-template-rows: repeat(2, min-content);
  row-gap: 2.8rem;
}

@media only screen and (max-width: 27.5em) {
  .collecte__row-6 .row__container {
    grid-template-columns: 1fr;
    column-gap: 0;
  }
}

.collecte__row-6 .row__container .right {
  color: #222;
  background-color: #fff;
  border-radius: .8rem;
  grid-area: 2 / 2 / 3 / 3;
  width: 100%;
  margin-left: -6rem;
  padding: 4rem min(11.4rem, 7vw);
  box-shadow: 0 4px 8px #0000000d;
}

@media only screen and (max-width: 51.25em) {
  .collecte__row-6 .row__container .right {
    padding: var(--body-horizontal-padding);
    background-color: #fff;
    border-radius: .8rem;
    box-shadow: 0 4px 8px #0000000d;
  }
}

@media only screen and (max-width: 30em) {
  .collecte__row-6 .row__container .right {
    padding: calc(var(--body-horizontal-padding) * 2);
    width: 120%;
    margin-left: -10rem;
  }
}

@media only screen and (max-width: 27.5em) {
  .collecte__row-6 .row__container .right {
    grid-column: 1;
    justify-self: center;
    width: 80%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 25.625em) {
  .collecte__row-6 .row__container .right {
    width: 100%;
  }
}

@media only screen and (max-width: 51.25em) {
  .collecte__row-6 .row__container h2 {
    width: 120%;
  }
}

@media only screen and (max-width: 48.75em) {
  .collecte__row-6 .row__container h2 {
    width: 130%;
  }
}

@media only screen and (max-width: 38.75em) {
  .collecte__row-6 .row__container h2 {
    width: 140%;
  }
}

@media only screen and (max-width: 32.5em) {
  .collecte__row-6 .row__container h2 {
    grid-column: 1 / 3;
    width: 80%;
  }
}

.collecte__row-6 .row__container h2:after {
  content: "";
  background-color: #3500fa;
  width: 6rem;
  height: 2px;
  margin: 3rem 0;
  display: block;
}

.collecte__row-7 {
  padding-bottom: 14.4rem;
}

.infos {
  padding-bottom: 16rem;
}

@media only screen and (max-width: 60em) {
  .infos {
    padding-bottom: 12rem;
  }
}

@media only screen and (max-width: 51.25em) {
  .infos {
    padding-bottom: 8rem;
  }
}

.infos__content {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: start;
  gap: 5.6rem;
  padding: 0 6.4rem;
  display: grid;
}

@media only screen and (max-width: 80em) {
  .infos__content {
    column-gap: 3.2rem;
    padding: 0 4.8rem;
  }
}

@media only screen and (max-width: 60em) {
  .infos__content {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 32.5em) {
  .infos__content {
    padding: calc(var(--body-horizontal-padding) * 2);
  }
}

@media only screen and (max-width: 27.5em) {
  .infos__content {
    padding: 0 var(--body-horizontal-padding);
  }
}

.infos .card {
  word-break: break-word;
  border-radius: 2.4rem;
  padding: 5.6rem;
  position: relative;
}

@media only screen and (max-width: 64em) {
  .infos .card {
    padding: 5rem;
  }
}

@media only screen and (max-width: 32.5em) {
  .infos .card {
    padding: 3.6rem;
  }
}

@media only screen and (max-width: 25.625em) {
  .infos .card {
    padding: 3rem;
  }
}

.infos .card--1 {
  color: #3500fa;
  background-color: #d7f9f3;
}

.infos .card--1 span {
  font-weight: 600;
}

.infos .card--2 .paragraph:nth-child(2), .infos .card--2 .paragraph:nth-child(5) {
  font-weight: 700;
  text-decoration: underline;
}

.infos .card--2 .paragraph:nth-child(3), .infos .card--2 .paragraph:nth-child(6) {
  margin-bottom: 1.2rem;
}

.infos .card--2 .btn-2:first-of-type {
  margin-bottom: 4.8rem;
}

@media only screen and (max-width: 60em) {
  .infos .card--3 {
    grid-row: 4;
  }
}

.infos .card--3 span {
  font-weight: 600;
}

.infos .card--3 .bus {
  margin-left: 2rem;
  font-weight: 600;
}

.infos .card--3 .paragraph {
  margin-bottom: 3.2rem;
}

.infos .card--4 {
  color: #3500fa;
  background-color: #f0edfd;
}

.infos .card--4 span {
  font-weight: 700;
}

.infos .card--4 .btn-2 {
  margin-top: 3.2rem;
}

.infos .card--4 .btn-2__icon {
  fill: #3500fa;
}

.infos .card--4 .btn-2__icon-facebook {
  width: 1.6rem;
  height: 1.6rem;
}

.infos .card--4 .btn-2__icon-instagram {
  width: 2rem;
  max-width: 2rem;
  height: 2rem;
}

.infos .card--4 .btn-2__icon-bg {
  border: 1px solid #3500fa;
}

.infos .card--5 {
  color: #3500fa;
  background-color: #d7f9f3;
}

.infos .card--5 .underline {
  text-decoration: underline;
}

.infos .card--5 .document {
  margin-left: 2rem;
}

.infos .card .star {
  fill: #3500fa;
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 1.2rem;
  left: 1.2rem;
}

@media only screen and (max-width: 32.5em) {
  .infos .card .star {
    top: 0;
    left: 0;
  }
}

@media only screen and (max-width: 25.625em) {
  .infos .card .star {
    width: 3.2rem;
    height: 3.2rem;
  }
}

.footer {
  background-color: #f5f5f7;
}

.footer .footer-content {
  text-align: center;
  padding: 3.2rem 0;
}

.footer .paragraph {
  justify-content: center;
  display: flex;
}

@media only screen and (max-width: 48.75em) {
  .footer .paragraph {
    flex-direction: column;
  }
}

.footer .paragraph span:not(:last-child) {
  margin-right: 3rem;
}

.footer .paragraph span:nth-child(2n):after {
  content: "|";
}

@media only screen and (max-width: 48.75em) {
  .footer .paragraph span:nth-child(2n):after {
    content: "•";
  }
}

.section-title__wrapper {
  margin-bottom: 15rem;
}

@media only screen and (max-width: 60em) {
  .section-title__wrapper {
    margin-bottom: 11rem;
  }
}

@media only screen and (max-width: 51.25em) {
  .section-title__wrapper {
    margin-bottom: 9rem;
  }
}

@media only screen and (max-width: 27.5em) {
  .section-title__wrapper {
    margin-bottom: 7rem;
  }
}

.btn {
  letter-spacing: .3px;
  border: 1px solid;
  border-radius: 7rem;
  align-items: center;
  column-gap: 1.6rem;
  padding: 1.6rem 2.4rem;
  line-height: 1.6rem;
  transition: all .3s ease-in-out;
  display: inline-flex;
}

@media (hover: hover) {
  .btn:hover {
    background-color: #93ecda;
    transform: translateY(-4px);
  }
}

@media only screen and (max-width: 64em) {
  .btn {
    font-weight: 500;
  }
}

.btn span {
  margin-top: -2px;
  margin-bottom: 2px;
}

.btn__icon {
  fill: currentColor;
  width: 1.4rem;
  height: 1.2rem;
}

.btn-2 {
  align-items: center;
  column-gap: 1rem;
  display: flex;
}

.btn-2__icon {
  fill: #222;
  width: 1.4rem;
  height: 1.2rem;
  position: relative;
}

.btn-2__icon-bg {
  border: 1px solid #222;
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 3.8rem;
  height: 3.8rem;
  transition: all .3s ease-in-out;
  display: flex;
}

@media (hover: hover) {
  .btn-2:hover .btn-2__icon-bg {
    background-color: #93ecda;
  }
}

.img__text {
  margin-top: .8rem;
  line-height: 150%;
}

.img__text div:first-child {
  letter-spacing: 1px;
}

.img__text div:nth-child(2) {
  letter-spacing: 0;
  column-gap: .6rem;
  font-size: 1.5rem;
  font-style: italic;
  display: flex;
}

.img__text div:nth-child(2):before {
  content: "";
  background-color: #222;
  width: 1.2rem;
  height: 1px;
  margin-top: 1.1rem;
  transform: translateY(2px);
}

.row-type--1 {
  margin-top: 14.4rem;
}

.row-type--1 .row__container {
  align-items: end;
}

@media only screen and (max-width: 60em) {
  .row-type--1 .row__container {
    align-items: start;
    row-gap: 6rem;
  }
}

.row-type--1 .paragraph {
  margin-bottom: 2.4rem;
}

.row-type--1 .text-content {
  margin-bottom: 7.2rem;
  padding-left: min(22.8rem, 14vw);
}

@media only screen and (max-width: 60em) {
  .row-type--1 .text-content {
    margin-bottom: 0;
    padding: 0 7vw 0 0;
  }
}

@media only screen and (max-width: 51.25em) {
  .row-type--1 .text-content {
    grid-column: 1 / 3;
    width: 70%;
    padding: 0 4vw;
  }
}

@media only screen and (max-width: 38.75em) {
  .row-type--1 .text-content {
    width: 75%;
  }
}

@media only screen and (max-width: 27.5em) {
  .row-type--1 .text-content {
    width: 80%;
  }
}

@media only screen and (max-width: 25.625em) {
  .row-type--1 .text-content {
    width: 100%;
  }
}

.row-type--1 .img-container:nth-child(2) {
  justify-self: end;
  justify-items: end;
  width: 90%;
  display: grid;
  transform: translateY(13rem);
}

@media only screen and (max-width: 60em) {
  .row-type--1 .img-container:nth-child(2) {
    place-self: end start;
    width: 100%;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 51.25em) {
  .row-type--1 .img-container:nth-child(2) {
    display: none;
  }
}

.row-type--1 .img-container:nth-child(2) img {
  aspect-ratio: 1;
  object-fit: cover;
  width: 100%;
  height: auto;
  box-shadow: 0 10px 20px #0000001a;
}

@media only screen and (max-width: 60em) {
  .row-type--1 .img-container:nth-child(2) img {
    box-shadow: none;
  }
}

.row-type--1 .img-container:nth-child(2) .img__text {
  flex-direction: column;
  align-items: end;
  display: flex;
}

.row-type--1 .img-container:nth-child(2) .img__text div:last-child:before {
  width: 0;
}

.row-type--1 .img-container:nth-child(2) .img__text div:last-child:after {
  content: "";
  background-color: #222;
  width: 1.2rem;
  height: 1px;
  margin-top: 1.1rem;
  transform: translateY(2px);
}

.row-type--1 .img-container:nth-child(3) {
  width: 125%;
}

@media only screen and (max-width: 60em) {
  .row-type--1 .img-container:nth-child(3) {
    width: 170%;
  }
}

@media only screen and (max-width: 51.25em) {
  .row-type--1 .img-container:nth-child(3) {
    grid-column: 1 / 3;
    width: 100%;
  }
}

.row-type--1 .img-container:nth-child(3) img {
  aspect-ratio: 1.77;
  object-fit: cover;
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 60em) {
  .collecte__row-7.row-type--1 .text-content {
    padding-left: 7vw;
  }
}

.collecte__row-7.row-type--1 .img-container:nth-child(2) {
  align-self: end;
  margin-top: 0;
}

.banner-coupdepouce {
  max-width: 100vw;
  margin: 20rem 0;
  overflow: hidden;
}

@media only screen and (max-width: 32.5em) {
  .banner-coupdepouce {
    margin: 16rem 0;
  }
}

.banner-coupdepouce .banner__content {
  grid-template-columns: repeat(8, max-content);
  align-items: center;
  column-gap: 18rem;
  width: -moz-fit-content;
  width: fit-content;
  animation: 16s linear infinite banner;
  display: grid;
}

.banner-coupdepouce .text-wrapper {
  font-size: 26rem;
  line-height: 26rem;
  display: flex;
}

@media only screen and (max-width: 36.25em) {
  .banner-coupdepouce .text-wrapper {
    padding: 2rem 0;
    font-size: 16rem;
  }
}

.banner-coupdepouce .text-wrapper--1 {
  letter-spacing: -.6rem;
  color: #63f1dc;
  font-family: ranade;
  font-weight: 700;
  line-height: 130%;
}

.banner-coupdepouce .text-wrapper--2 {
  color: #e8e2fd;
  font-family: Barlow;
  font-style: normal;
  font-weight: 900;
  line-height: 130%;
}

.banner-coupdepouce .separator {
  background-color: #e3e3e3;
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
}

@keyframes banner {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(calc(-50% - 9rem));
  }
}

.about__objectifs .heading-wrapper {
  justify-content: center;
  margin-bottom: 12rem;
  display: flex;
}

@media only screen and (max-width: 25.625em) {
  .about__objectifs .heading-wrapper {
    margin-bottom: 8rem;
  }
}

.about__objectifs .heading-primary {
  align-items: center;
  display: flex;
}

.about__objectifs .heading-primary:before, .about__objectifs .heading-primary:after {
  content: "";
  background-color: #222;
  width: 3rem;
  height: 2px;
  margin-top: 1rem;
  display: inline-block;
}

@media only screen and (max-width: 27.5em) {
  .about__objectifs .heading-primary:before, .about__objectifs .heading-primary:after {
    width: 2rem;
  }
}

.about__objectifs .heading-primary:before {
  margin-right: 2rem;
}

.about__objectifs .heading-primary:after {
  margin-left: 2rem;
}

.about__objectifs .row__container {
  align-items: center;
  row-gap: 12rem;
}

@media only screen and (max-width: 51.25em) {
  .about__objectifs .row__container {
    grid-template-rows: repeat(2, min-content);
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 3.8rem 0;
  }
}

@media only screen and (max-width: 27.5em) {
  .about__objectifs .row__container {
    row-gap: 2.4rem;
  }
}

.about__objectifs h3 {
  color: #222;
  letter-spacing: .1rem;
  font-family: Barlow;
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
}

.about__objectifs p {
  border-bottom: 2px solid #222;
  margin-top: .6rem;
  padding-bottom: 2.4rem;
}

.about__objectifs p span {
  font-style: italic;
  font-weight: 700;
}

.about__objectifs .right {
  margin-bottom: 2rem;
}

@media only screen and (max-width: 51.25em) {
  .about__objectifs .right {
    width: 60%;
    margin-bottom: 10rem;
  }
}

@media only screen and (max-width: 36.25em) {
  .about__objectifs .right {
    width: 80%;
    margin-bottom: 10rem;
  }
}

@media only screen and (max-width: 27.5em) {
  .about__objectifs .right {
    width: 90%;
    margin-bottom: 10rem;
  }
}

.about__objectifs .left {
  justify-content: center;
  width: 100%;
  display: flex;
}

.about__objectifs .img-01 {
  aspect-ratio: 1.88;
  width: 90%;
  height: auto;
}

@media only screen and (max-width: 51.25em) {
  .about__objectifs .img-01 {
    aspect-ratio: 1.88;
    width: 100%;
  }
}

.about__objectifs .img-02 {
  object-fit: cover;
  aspect-ratio: 1.2;
  width: 70%;
  height: auto;
}

@media only screen and (max-width: 51.25em) {
  .about__objectifs .img-02 {
    aspect-ratio: 1.2;
    width: 80%;
  }
}

.about__objectifs .img-03 {
  aspect-ratio: 1.42;
  width: 70%;
  height: auto;
}

@media only screen and (max-width: 51.25em) {
  .about__objectifs .img-03 {
    aspect-ratio: 1.42;
    width: 92%;
  }
}

/*# sourceMappingURL=index.a8dd7113.css.map */
