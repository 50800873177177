//######################################################################
//######################################################################

//                          HEADER

//######################################################################
//######################################################################

// MEDIA QUERY
@media only screen and (max-width: $break-3) {
  .nav-open {
    height: 100vh;
    overflow: hidden;
    // padding-right: 15px;
  }
}

.header {
  @extend %row;

  & {
    position: fixed;
    top: 0;
    width: 100%;
    transition: all 0.2s ease-in-out;
    background-color: white;
    z-index: 100;
  }

  .scroll-down & {
    transform: translateY(-100%);
  }

  .scroll-up & {
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.03);
  }

  &__container {
    @extend %container;
    // background-color: $body-color;

    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 5.6rem;

    padding: 2.4rem 0;
    // padding: 1.8rem 0;

    // MEDIA QUERY
    @media only screen and (max-width: $break-3) {
      column-gap: 4.8rem;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-9) {
      padding: 1.6rem 0;
      column-gap: 3.2rem;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-13) {
      column-gap: 2.4rem;
    }

    .nav-open & {
      // padding-right: 15px;
    }
  }

  // ATTENTION put the logo on .png
  &__logo {
    height: 5rem;
    // max-width: 10.6rem;
    width: 8.5rem;
    display: block;
    fill: $color-text;
    z-index: 11;

    // MEDIA QUERY
    @media only screen and (max-width: $break-9) {
      height: 4rem;
      width: 6.8rem;
    }
  }

  &__menu {
    margin-left: auto;
    align-self: stretch;

    // MEDIA QUERY
    @media only screen and (max-width: $break-3) {
      position: fixed;
      width: 100%;
      transform: translateX(100%);
      height: calc(100vh - 9.8rem);
      top: 9.8rem;
      right: 0;
      overflow-x: hidden;
      background-color: $primary-color;
      z-index: 10;
      // transition: transform 0.2s ease-in-out, visibility 0.2s ease-in-out;
      visibility: hidden;

      &.with-transition {
        transition: transform 0.2s ease-in-out, visibility 0.2s ease-in-out;
      }

      .nav-open & {
        transform: translateX(0%);
        visibility: visible;
        display: initial;
        transition: transform 0.2s ease-in-out, visibility 0.2s ease-in-out;
      }
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-9) {
      top: 7.2rem;
      height: calc(100vh - 7.2rem);
    }
  }

  &__icons {
    display: flex;
    align-items: center;
    column-gap: 1.6rem;

    // MEDIA QUERY
    @media only screen and (max-width: $break-3) {
      margin-left: auto;
    }
  }

  &__icon {
    display: flex;
    width: 3.2rem;
    height: 3.2rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.4rem;
    background-color: $secondary-color--var-2;
    transition: all 0.3s ease-in-out;

    @media (hover: hover) {
      &:hover {
        background-color: $secondary-color--var-1;
        transform: translateY(-4px);
      }
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-3) {
      width: 3.8rem;
      height: 3.8rem;
    }
  }

  &__facebook {
    height: 1.6rem;
    width: 1.6rem;
    fill: $color-text;

    // MEDIA QUERY
    @media only screen and (max-width: $break-3) {
      width: 2rem;
      height: 2rem;
    }
  }

  .mobile-nav__btn {
    display: none;

    // MEDIA QUERY
    @media only screen and (max-width: $break-3) {
      display: block;
      z-index: 11;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-9) {
      margin-right: 1rem;
    }
  }

  .mobile-nav__icon {
    height: 3rem;
    width: 3rem;
    color: $color-text;
    transition: all 0.3s ease-in-out;

    @media (hover: hover) {
      &:hover {
        transform: scale(1.15);
      }
    }

    &--close {
      display: none;

      .nav-open & {
        display: block;
      }
    }

    .nav-open &--open {
      display: none;
    }
  }
}

.menu {
  display: flex;
  column-gap: 5.6rem;
  letter-spacing: 0.3px;
  height: 100%;
  align-items: stretch;

  // MEDIA QUERY
  @media only screen and (max-width: $break-3) {
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 8rem;
    margin-left: 3.6rem;
    // margin-right: 3.6rem;
    margin-top: 12rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-12) {
    margin-left: 2rem;
  }

  &__item {
    @extend %text;
    font-weight: 400;
    font-size: 1.8rem;
    display: flex;
    align-items: stretch;
    // overflow: hidden;

    .main-nav-link {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;

      &::after {
        content: "";
        position: absolute;
        display: block;
        bottom: 3px;
        left: 0;
        width: 0;
        height: 2px;
        background-color: $secondary-color--var-1;
        // background-color: $primary-color--var-1;
        transition: all 0.25s ease-in-out;
        // transform: scaleX(0);
        // transform-origin: left;
      }

      &:hover {
        &::after {
          // transform: scaleX(1);
          width: 100%;
        }
      }
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-3) {
      font-size: 3.6rem;
      font-weight: 400;
      // display: block;
      // width: 50%;
      // border-bottom: 1px solid #bbbbbb;
      // padding-bottom: 1.6rem;

      &::before {
        content: ".";
        margin-right: 2rem;

        // MEDIA QUERY
        @media only screen and (max-width: $break-11) {
          margin-right: 1rem;
        }
      }
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-9) {
      font-size: 3rem;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-12) {
      font-size: 2.8rem;
    }
  }
}

main {
  margin-top: 9.8rem;
  // margin-top: 8.6rem;

  // MEDIA QUERY
  @media only screen and (max-width: $break-9) {
    margin-top: 7.2rem;
  }
}

//######################################################################
//######################################################################

//                          SECTION CTA

//######################################################################
//######################################################################

.cta {
  @extend %row;
  position: relative;
  padding-top: 7rem;
  padding-bottom: 11rem;
  margin-bottom: 6rem;
  background-color: $body-color;
  // z-index: -2;

  // MEDIA QUERY
  @media only screen and (max-width: $break-8) {
    margin-bottom: 3.4rem;
  }

  .illustration {
    width: 20rem;
    position: absolute;
    bottom: 0;
    left: 40%;
    transform: rotate(-12deg);
    filter: brightness(1.15);

    img {
      width: 100%;
    }
  }

  .bg {
    position: absolute;
    background-color: $primary-color;
    // background-image: $gradient;
    top: 0;
    left: 0;
    height: 76.8rem;
    height: 95%;
    width: 55%;
    // z-index: -1;
    border-radius: 0 0.8rem 0.8rem 0;

    // MEDIA QUERY
    @media only screen and (max-width: $break-5) {
      height: 50%;
      width: 80%;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-5) {
      // height: 55%;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-7) {
      width: 90%;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-8) {
      height: 55%;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-9) {
      // width: 95%;
      // height: 55%;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-11) {
      // width: 96%;
      width: 100%;
      border-radius: 0;
      height: 60%;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-12) {
      // width: 96%;
      height: 60%;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-13) {
      // width: 96%;
      // height: 62%;
    }
  }

  &__container {
    @extend %container, %two-cols;

    // MEDIA QUERY
    @media only screen and (max-width: $break-5) {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, min-content);
      row-gap: 6rem;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-8) {
      row-gap: 5rem;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-12) {
      row-gap: 7rem;
    }
  }

  .left {
    @extend %ct-centered-it-start;
    align-self: stretch;
    grid-template-rows: repeat(2, min-content) min-content;
    align-content: center;

    border-radius: 2.4rem;
    padding: 7.2rem min(11.4rem, 7vw);

    position: relative;

    // MEDIA QUERY
    @media only screen and (max-width: $break-4) {
      padding: 7.2rem 4vw;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-5) {
      padding-top: 0;
      padding-bottom: 0;
      width: 90%;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-7) {
      width: 70%;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-8) {
      width: 85%;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-9) {
      width: 95%;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-12) {
      width: 100%;
    }

    .heading-primary {
      font-size: 6.4rem;
      line-height: 6.4rem;
      margin-bottom: 0;
      color: $color-text--var-2;

      // MEDIA QUERY
      @media only screen and (max-width: $break-2) {
        font-size: 5.6rem;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        font-size: 5rem;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        width: 70%;
        span {
          display: inline-block;
          // width: 100%;
        }
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 100%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-10) {
        font-size: 4.8rem;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-11) {
        font-size: 4.6rem;
        line-height: 5.4rem;
        width: 100%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-12) {
        font-size: 4.4rem;
        line-height: 5.4rem;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-13) {
        font-size: 4.2rem;
      }

      &::after {
        content: "";
        display: block;
        width: 6rem;
        height: 2px;
        margin: 3rem 0;
        background-color: $color-text--var-2;

        // MEDIA QUERY
        @media only screen and (max-width: $break-11) {
          width: 5rem;
        }
      }
    }

    .paragraph {
      margin-top: -0.4rem;
      margin-bottom: 5.2rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        margin-bottom: 3rem;
        width: 60%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 100%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-8) {
        width: 80%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-9) {
        width: 75%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-12) {
        width: 100%;
      }
    }

    .btn {
      align-self: end;
      color: $color-text--var-2;
    }

    .circle {
      position: absolute;
      display: block;
      width: 3.4rem;
      height: 3.4rem;
      border-radius: 50%;
      background-color: #00ebbf;
      top: 8%;
      right: 8%;
    }

    .triangle {
      position: absolute;
      width: 3.5rem;
      height: 3.2rem;
      bottom: 16%;
      right: 12%;
    }

    .rectangle {
      position: absolute;
      width: 2.4rem;
      height: 4rem;
      transform: rotate(165deg);
      border-radius: 0.4rem;
      background: #f05ce0;
      bottom: 25%;
      left: 9%;
    }
  }

  .right {
    // align-self: stretch;
    border-radius: 2.4rem;
    overflow: hidden;
    z-index: 2;

    // MEDIA QUERY
    @media only screen and (max-width: $break-3) {
      align-self: stretch;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-5) {
      width: 80%;
      justify-self: end;
      align-self: start;
      // margin-right: calc(var(--body-horizontal-padding) * -1);
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-6) {
      // width: 100%;
      // justify-self: center;
      // padding: 0 4vw;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-7) {
      padding: 0;
      justify-self: end;
      width: 90%;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-9) {
      // margin-right: calc(var(--body-horizontal-padding) * -1);
      // width: 95%;
      // width: 80%;
      // padding-right: 4vw;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-10) {
      border-radius: 1.6rem;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-12) {
      width: 100%;
    }

    img {
      width: 100%;
      height: auto;
      aspect-ratio: 1;
      object-fit: cover;
      border-radius: 2.4rem;
      // display: none;
      // height: 100%;

      // MEDIA QUERY
      @media only screen and (max-width: $break-2) {
        scale: 1.1;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-3) {
        height: 100%;
        width: 100%;
        scale: 1;
        object-position: 40% 100%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        // aspect-ratio: 4 / 3;
        object-position: 50% 20%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-8) {
        scale: 1.2;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-9) {
        scale: 1.3;
        aspect-ratio: 1;
      }
    }
  }
}

//######################################################################
//######################################################################

//                          SECTION ABOUT

//######################################################################
//######################################################################
.about {
  // padding-top: $section-padding-top;
  @extend %section;

  &__row-1 {
    @extend %row;

    .row__container {
      @extend %container, %two-cols;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, min-content);
        row-gap: 6rem;
        column-gap: 0;
      }
    }

    .left {
      @extend %ct-centered-it-start;

      padding: 0 min(11.4rem, 7vw);

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        padding: 0;
        padding-right: 7vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        padding: 0 4vw;
        width: 70%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 75%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-12) {
        width: 100%;
      }
    }

    .right {
      justify-self: end;
      width: 90%;
      display: grid;
      justify-items: start;

      // MEDIA QUERY
      @media only screen and (max-width: $break-1) {
        width: 100%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        // align-self: stretch;
        // grid-template-rows: 1fr min-content;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        justify-self: end;
        grid-row: 2;
        width: 75%;
        padding-right: 4vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        padding: 0;
        justify-self: end;
        width: 85%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-11) {
        width: 100%;
      }

      img {
        // display: none;
        width: 100%;
        height: auto;
        aspect-ratio: 1.33;
        object-fit: cover;

        // MEDIA QUERY
        @media only screen and (max-width: $break-4) {
          height: 100%;
          object-position: 70% center;
          // min-height: 100%;
        }

        // MEDIA QUERY
        @media only screen and (max-width: $break-9) {
          // scale: 1.1;
        }
      }
    }
  }

  &__row-2 {
    padding: 12rem 0;
  }

  &__logo-2 {
    margin: 0 auto;
    width: 52.2rem;
    height: 52.2rem;
    // border-radius: 50%;
    // background-color: $secondary-color;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.03);

    // MEDIA QUERY
    @media only screen and (max-width: $break-8) {
      width: 44rem;
      height: 44rem;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-10) {
      width: 38rem;
      height: 38rem;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-11) {
      width: 35rem;
      height: 35rem;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-13) {
      width: 32rem;
      height: 32rem;
    }

    img {
      width: 100%;
      height: 100%;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.03);
      border-radius: 50%;
    }

    // svg {
    //   fill: $color-text--var-2;
    //   height: 28.2rem;
    //   width: 35.2rem;

    //   // MEDIA QUERY
    //   @media only screen and (max-width: $break-8) {
    //     scale: 0.85;
    //   }

    //   // MEDIA QUERY
    //   @media only screen and (max-width: $break-10) {
    //     scale: 0.8;
    //   }

    //   // MEDIA QUERY
    //   @media only screen and (max-width: $break-11) {
    //     scale: 0.7;
    //   }
    // }
  }

  &__row-3 {
    @extend %row;

    .row__container {
      @extend %container, %two-cols;
      align-items: end;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        // column-gap: 4rem;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, min-content);
        row-gap: 6rem;
        column-gap: 0;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-6) {
        // grid-template-columns: 1fr;
        // grid-template-rows: repeat(2, min-content);
        // row-gap: 6rem;
        // column-gap: 0;
      }
    }

    .left {
      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        padding: 0 4vw;
        width: 70%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 75%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-10) {
        width: 80%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-11) {
        width: 100%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-12) {
        width: 100%;
      }

      .heading-primary {
        margin-bottom: 0;
      }

      .btn-2 {
        margin-top: 2.4rem;
      }
    }

    .right {
      @extend %ct-centered-it-start;

      padding-left: min(calc(11.4rem * 2), calc(7vw * 2));

      .paragraph {
        margin-bottom: 2.4rem;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        padding-left: 7vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        padding-left: 0;
        justify-self: end;
        width: 75%;
        padding-right: 4vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-6) {
        // justify-self: end;
        // width: 75%;
        // padding-right: 4vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-12) {
        width: 100%;
        padding: 0 4vw;
      }
    }
  }

  &__row-4 {
    @extend %row;
    margin-top: 14.4rem;
    padding-top: 10rem;
    padding-bottom: 10rem;
    background-color: $color-grey--light;

    // MEDIA QUERY
    @media only screen and (max-width: $break-11) {
      padding-top: 8rem;
      padding-bottom: 8rem;
    }

    .row__container {
      @extend %container;

      display: flex;
      flex-direction: column;
      align-items: center;

      .quote {
        width: 90%;
        margin-bottom: 2.4rem;

        // MEDIA QUERY
        @media only screen and (max-width: $break-11) {
          margin-bottom: 4.8rem;
        }

        // MEDIA QUERY
        @media only screen and (max-width: $break-12) {
          width: 100%;
        }
      }

      .grigny-la-grande-borne {
        position: relative;
        margin-top: 5.6rem;
        width: 80%;

        // MEDIA QUERY
        @media only screen and (max-width: $break-6) {
          min-width: 80%;
        }

        // MEDIA QUERY
        @media only screen and (max-width: $break-10) {
          min-width: 90%;
        }

        // MEDIA QUERY
        @media only screen and (max-width: $break-12) {
          width: 100%;
        }

        img {
          width: 100%;
          aspect-ratio: 16 / 9;
          object-fit: cover;
        }

        &__legende {
          @extend %legende;
          position: absolute;
          bottom: 0;
          left: 0;
          transform: translateY(200%);

          // MEDIA QUERY
          @media only screen and (max-width: $break-7) {
            bottom: -10%;
          }
        }
      }
    }
  }

  &__row-5 {
  }

  &__row-6 {
    @extend %row;
    margin-top: 12rem;

    .row__container {
      @extend %container, %two-cols;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        // column-gap: 4rem;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, min-content);
        row-gap: 6rem;
        column-gap: 0;
      }
    }

    .img-container {
      width: 90%;

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        width: 100%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        justify-self: end;
        grid-row: 2;
        width: 75%;
        padding-right: 4vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 80%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-10) {
        padding: 0;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-12) {
        width: 100%;
      }

      img {
        // display: none;
        aspect-ratio: 1;
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    .right {
      @extend %ct-centered-it-start;
      padding: 0 min(11.4rem, 7vw);

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        padding: 0;
        padding-left: 7vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        // padding-left: 0;
        grid-row: 1;
        padding: 0 4vw;
        width: 70%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 75%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-11) {
        width: 80%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-12) {
        width: 100%;
      }
    }
  }

  &__row-7 {
    @extend %row;
    margin-top: 12rem;

    .row__container {
      @extend %container, %two-cols;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        // column-gap: 4rem;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, min-content);
        row-gap: 6rem;
        column-gap: 0;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-12) {
        row-gap: 3.2rem;
      }
    }

    .left {
      @extend %ct-centered-it-start;
      padding: 0 min(11.4rem, 7vw);

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        padding: 0;
        padding-right: 7vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        justify-self: end;
        grid-row: 2;
        width: 75%;
        padding-right: 4vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-12) {
        width: 100%;
        padding: 0 4vw;
      }
    }

    .right {
      @extend %ct-centered-it-start;
      padding: 0 min(11.4rem, 7vw);

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        padding: 0;
        padding-right: 7vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        // padding-left: 0;
        grid-row: 1;
        padding: 0 4vw;
        width: 70%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 75%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-11) {
        width: 80%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-12) {
        width: 100%;
        padding: 0 4vw;
      }

      .heading-primary {
        margin-bottom: 0;
      }
    }
  }

  &__row-8 {
    @extend %row;
    margin-top: 14.4rem;

    // MEDIA QUERY
    @media only screen and (max-width: $break-11) {
      margin-top: 10rem;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-13) {
      margin-top: 8rem;
    }

    .row__container {
      @extend %container;
      padding: 10rem 0;
      // padding: min(10rem, 5vw);
      background-color: $primary-color;
      border-radius: 2.4rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-1) {
        // padding: 8rem 0;
        padding: min(10rem, 5vw) 3vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        padding: min(10rem, 7vw) 3vw;
      }
    }

    .quote {
      width: 90%;
      margin: 0 auto;
      font-weight: 400;
      line-height: 160%;
      color: $color-text;

      span {
        font-size: 4.8rem;
        font-weight: 700;
        color: $color-text;

        // MEDIA QUERY
        @media only screen and (max-width: $break-10) {
          font-size: 4rem;
        }

        // MEDIA QUERY
        @media only screen and (max-width: $break-13) {
          font-size: 3.8rem;
        }
      }
    }
  }

  &__row-9 {
    @extend %row;
    margin-top: 14.4rem;

    .row__container {
      @extend %container, %two-cols;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, min-content);
        row-gap: 6rem;
        column-gap: 0;
      }
    }

    .left {
      @extend %ct-centered-it-start;
      padding: 0 min(11.4rem, 7vw);

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        padding: 0;
        padding-right: 7vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        // padding-left: 0;
        grid-row: 1;
        padding: 0 4vw;
        width: 70%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 75%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-11) {
        width: 80%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-12) {
        width: 100%;
      }
    }

    .img-container {
      width: 90%;

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        width: 100%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        justify-self: end;
        grid-row: 2;
        width: 75%;
        padding-right: 4vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 80%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-10) {
        padding: 0;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-12) {
        width: 100%;
      }

      img {
        width: 100%;
        height: auto;
        aspect-ratio: 1;
        object-fit: cover;
        // display: none;
      }
    }
  }

  &__row-10 {
    @extend %row;
    margin-top: 20rem;

    // MEDIA QUERY
    @media only screen and (max-width: $break-12) {
      margin-top: 16rem;
    }

    .row__container {
      @extend %container;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .circle {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      // background-color: $color-grey;

      &:first-child {
        margin-bottom: 6rem;
        background-color: $color-grey;
      }

      &:last-child {
        margin-top: 6rem;
        background-color: $color-grey;
      }
    }

    .quote {
      width: 50%;
      margin: 0 auto;
      font-size: 5.6rem;
      font-weight: 400;
      text-align: start;

      // MEDIA QUERY
      @media only screen and (max-width: $break-2) {
        width: 60%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-3) {
        font-size: 4.8rem;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        width: 70%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 80%;
        font-size: 4.4rem;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-10) {
        font-size: 4rem;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-12) {
        font-size: 3.6rem;
      }

      .coupdepouce {
        font-weight: 700;
      }
    }

    span:last-of-type {
      font-weight: 700;
      // text-decoration: underline;
    }
  }

  &__row-11 {
    @extend %row;
    margin-top: 20rem;
    margin-bottom: 14.4rem;

    // // MEDIA QUERY
    // @media only screen and (max-width: $break-11) {
    //   margin-bottom: 10rem;
    // }

    // MEDIA QUERY
    @media only screen and (max-width: $break-12) {
      margin-top: 16rem;
    }

    .row__container {
      @extend %container, %two-cols;
      align-items: start;

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        grid-template-columns: 1fr;
        row-gap: 3.2rem;
      }
    }

    .heading-primary {
      font-size: 4rem;
      margin-bottom: 4.8rem;
    }

    .card-left {
      padding: 4.8rem;
      border-radius: 2.4rem;
      background-color: $secondary-color;

      color: $color-text--var-2;

      // MEDIA QUERY
      @media only screen and (max-width: $break-9) {
        padding: 3.6rem;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-12) {
        padding: 3rem;
      }

      span {
        margin-left: 2rem;
      }
    }

    .card-right {
      padding: 4.8rem;
      border-radius: 2.4rem;
      background-color: $primary-color;

      color: $color-text--var-2;

      // MEDIA QUERY
      @media only screen and (max-width: $break-9) {
        padding: 3.6rem;
      }
    }
  }
}

//######################################################################
//######################################################################

//                          SECTION COLLECTE

//######################################################################
//######################################################################

.collecte {
  @extend %section;

  &__row-1 {
    @extend %row;

    .row__container {
      @extend %container;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, min-content);
      column-gap: 6rem;
      align-items: end;
      // background-color: $color-grey--light;
      // border-radius: 2.4rem;
      // padding: 4.8rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        column-gap: 4rem;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-6) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, min-content);
        row-gap: 6rem;
        column-gap: 0;
      }
    }

    .quote-wrapper {
      display: flex;
      flex-direction: column;

      // &::before {
      //   content: "";
      //   display: inline-block;
      //   width: 1.4rem;
      //   height: 1.4rem;
      //   border-radius: 50%;
      //   // background-color: #00ebbf;
      //   // background-color: rgba(16, 12, 228, 0.4);
      //   margin-left: 5rem;
      //   margin-bottom: -2rem;
      // }

      // &:first-child::before {
      //   background-color: #00ebbf;
      // }
      // &:last-child::before {
      //   background-color: rgba(16, 12, 228, 0.4);
      // }

      &:first-child {
        // MEDIA QUERY
        @media only screen and (max-width: $break-6) {
          padding: 0 4vw;
          width: 70%;
        }

        // MEDIA QUERY
        @media only screen and (max-width: $break-7) {
          width: 75%;
        }

        // MEDIA QUERY
        @media only screen and (max-width: $break-11) {
          width: 85%;
        }

        // MEDIA QUERY
        @media only screen and (max-width: $break-12) {
          width: 100%;
        }
      }

      &:last-child {
        // MEDIA QUERY
        @media only screen and (max-width: $break-6) {
          justify-self: end;
          width: 75%;
          padding-right: 4vw;
        }

        // MEDIA QUERY
        @media only screen and (max-width: $break-11) {
          width: 80%;
        }

        // MEDIA QUERY
        @media only screen and (max-width: $break-12) {
          width: 100%;
          padding: 0 4vw;
        }
      }
    }

    .quote {
      margin: 0 auto;
      display: inline-block;
      // border-radius: 2.4rem;
      padding: 5rem;
      padding-top: 0;

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        padding: 2.5rem;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-6) {
        padding: 0;
      }

      &:first-child {
        text-align: left;
        // background-color: rgba(16, 12, 228, 0.4);
        // background-color: $color-grey;
        // border-radius: 2.4rem 0 0 2.4rem;
      }

      &:last-child {
        // grid-row: 2 / 3;
        // grid-column: 2 / 3;
        // margin-top: -20rem;
        text-align: left;
        // background-color: #fff6ea;
        // border-radius: 0 2.4rem 2.4rem 0;
      }
    }
  }

  &__row-2 {
    @extend %row;
    margin-top: 12rem;
    // padding-top: 10rem;
    // padding-bottom: 10rem;
    // background-color: $secondary-color;
    position: relative;
    z-index: 1;
    overflow: hidden;

    .row__container {
      @extend %container, %two-cols;

      padding: 10rem 0;
      background-color: $secondary-color;
      border-radius: 2.4rem;
      // position: relative;

      // MEDIA QUERY
      @media only screen and (max-width: $break-6) {
        grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-8) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, min-content);
        row-gap: 6rem;
        column-gap: 0;
        padding: 6rem 0;
      }
    }

    .heading-primary {
      margin-bottom: 0;
    }

    .left {
      // padding-left: min(calc(11.4rem * 2), calc(7vw * 2));
      display: flex;
      justify-content: center;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        // padding: 0;
        // padding-left: 4vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-8) {
        grid-row: 2 / 3;
        padding-left: 8vw;
        justify-content: end;
        width: 75%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-11) {
        width: 100%;
        justify-content: end;
        padding-right: 8rem;
      }
    }

    .right {
      padding: 0 min(11.4rem, 7vw);

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        padding-left: 0;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-8) {
        // padding-right: 4vw;
        grid-row: 1 / 2;
        // justify-self: end;
        width: 75%;
        padding-left: 4vw;
        // display: grid;
        // justify-content: end;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-11) {
        width: 100%;
        padding: 0 4vw;
      }
    }

    ul {
      font-family: "barlow";
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 180%;
      text-rendering: geometricPrecision;
      text-transform: uppercase;
      // margin-right: 8rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        // margin-left: 4rem;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-10) {
        display: inline-block;
      }

      li:not(:last-child) {
        margin-bottom: 4.8rem;
      }

      li:last-child {
        text-transform: lowercase;
      }
    }
  }

  &__row-3 {
    @extend %row;
    margin-top: 17rem;

    .row__container {
      @extend %container, %two-cols;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, min-content);
        row-gap: 6rem;
        column-gap: 0;
      }
    }

    .img-container {
      width: 90%;
      justify-self: end;

      // MEDIA QUERY
      @media only screen and (max-width: $break-1) {
        width: 100%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        justify-self: end;
        grid-row: 2;
        width: 75%;
        padding-right: 4vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 85%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-9) {
        width: 90%;
        padding: 0;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-11) {
        width: 100%;
      }

      img {
        width: 100%;
        height: auto;
        aspect-ratio: 1.33;
        object-fit: cover;
      }
    }

    .right {
      @extend %ct-centered-it-start;
      padding: 0 min(11.4rem, 7vw);

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        padding: 0;
        padding-left: 7vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        // padding-left: 0;
        grid-row: 1;
        padding: 0 4vw;
        width: 70%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 75%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-11) {
        width: 80%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-12) {
        width: 100%;
      }
    }
  }

  &__row-4 {
    @extend %row;
    margin-top: 12rem;
    // position: relative;
    // z-index: 1;

    .row__container {
      @extend %container, %two-cols;
      // padding-top: 6.4rem;
      padding-bottom: 6.4rem;
      // background-color: $tertiary-color;
      // border-radius: 2.4rem;
      // position: relative;
      // overflow: hidden;

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        // padding-left: 4.8rem;
        // padding-right: 4.8rem;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        padding: 6.4rem var(--body-horizontal-padding);
        column-gap: 4rem;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-6) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, min-content);
        row-gap: 6rem;
        column-gap: 0;
      }
    }

    .heading-primary {
      margin-bottom: 0;
    }

    .left {
      @extend %ct-centered-it-start;
      padding: 0 min(11.4rem, 7vw);
      z-index: 2;

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        padding: 0;
        padding-right: 7vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        padding: 0;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-6) {
        justify-self: end;
        width: 75%;
        padding-right: 4vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-12) {
        width: 100%;
        padding-right: 0;
      }
    }

    .right {
      @extend %ct-centered-it-start;
      padding: 0 min(11.4rem, 7vw);
      z-index: 2;

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        padding: 0;
        padding-left: 7vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        padding: 0;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-6) {
        padding: 0 4vw;
        width: 70%;
        grid-row: 1;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 75%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-11) {
        width: 80%;
        padding: 0;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-12) {
        width: 100%;
      }
    }
  }

  &__row-5 {
    @extend %row;
    margin-top: 12rem;

    .row__container {
      @extend %container, %two-cols;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, min-content);
        row-gap: 6rem;
        column-gap: 0;
      }
    }

    .heading-primary {
      margin-bottom: 0;
    }

    .left {
      @extend %ct-centered-it-start;
      padding: 0 min(11.4rem, 7vw);

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        padding: 0;
        padding-right: 7vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        padding-right: 4vw;
        width: 75%;
        justify-self: end;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-11) {
        width: 80%;
      }
    }

    .img-container {
      width: 90%;

      // MEDIA QUERY
      @media only screen and (max-width: $break-1) {
        width: 100%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        justify-self: start;
        grid-row: 2;
        width: 75%;
        padding-left: 4vw;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 85%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-9) {
        width: 90%;
        padding: 0;
      }

      img {
        width: 100%;
        height: auto;
        aspect-ratio: 1.33;
        object-fit: cover;
      }
    }
  }

  &__row-6 {
    @extend %row;
    margin-top: 12rem;
    padding-top: 10rem;
    padding-bottom: 10rem;
    // background-color: $primary-color;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      background-color: $primary-color;
      width: 50%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: 0 2.4rem 2.4rem 0;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        width: 60%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-6) {
        width: 68%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 70%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 80%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-9) {
        width: 90%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-12) {
        width: 95%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-13) {
        width: 100%;
        border-radius: 0;
      }
    }

    .row__container {
      @extend %container, %two-cols;
      grid-template-rows: repeat(2, min-content);
      row-gap: 2.8rem;
      color: $color-text--var-2;

      // MEDIA QUERY
      @media only screen and (max-width: $break-11) {
        grid-template-columns: 1fr;
        column-gap: 0;
      }

      .right {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
        color: $color-text;
        padding: 4rem min(11.4rem, 7vw);
        border-radius: 0.8rem;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
        background-color: white;
        margin-left: -6rem;
        width: 100%;

        // MEDIA QUERY
        @media only screen and (max-width: $break-5) {
          padding: var(--body-horizontal-padding);
          border-radius: 0.8rem;
          background-color: white;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
        }

        // MEDIA QUERY
        @media only screen and (max-width: $break-10) {
          padding: calc(var(--body-horizontal-padding) * 2);
          margin-left: -10rem;
          width: 120%;
        }

        // MEDIA QUERY
        @media only screen and (max-width: $break-11) {
          grid-column: 1;
          margin-left: 0;
          width: 80%;
          justify-self: center;
        }

        // MEDIA QUERY
        @media only screen and (max-width: $break-12) {
          width: 100%;
        }

        // MEDIA QUERY
        @media only screen and (max-width: $break-13) {
        }
      }

      h2 {
        // word-break: break-word;

        // MEDIA QUERY
        @media only screen and (max-width: $break-5) {
          width: 110%;
        }

        // MEDIA QUERY
        @media only screen and (max-width: $break-5) {
          width: 120%;
        }

        // MEDIA QUERY
        @media only screen and (max-width: $break-6) {
          width: 130%;
        }

        // MEDIA QUERY
        @media only screen and (max-width: $break-7) {
          width: 140%;
        }

        // MEDIA QUERY
        @media only screen and (max-width: $break-9) {
          grid-column: 1 / 3;
          width: 80%;
        }

        &::after {
          content: "";
          display: block;
          width: 6rem;
          height: 2px;
          margin: 3rem 0;
          background-color: $color-text--var-2;
        }
      }
    }
  }

  &__row-7 {
    padding-bottom: 14.4rem;

    // // MEDIA QUERY
    // @media only screen and (max-width: $break-11) {
    //   padding-bottom: 10rem;
    // }

    .text-content {
      // // MEDIA QUERY
      // @media only screen and (max-width: $break-4) {
      //   padding-left: 7vw;
      // }
    }
  }
}

//######################################################################
//######################################################################

//                          SECTION INFOS

//######################################################################
//######################################################################
.infos {
  @extend %section;
  padding-bottom: 16rem;

  // MEDIA QUERY
  @media only screen and (max-width: $break-4) {
    padding-bottom: 12rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-5) {
    padding-bottom: 8rem;
  }

  &__content {
    @extend %container;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    // grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
    gap: 5.6rem;
    align-items: start;
    padding: 0 6.4rem;
    // background-color: rgba(242, 252, 202, 0.4);

    // MEDIA QUERY
    @media only screen and (max-width: $break-1) {
      column-gap: 3.2rem;
      padding: 0 4.8rem;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-4) {
      grid-template-columns: 1fr;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-9) {
      padding: calc(var(--body-horizontal-padding) * 2);
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-11) {
      padding: 0 var(--body-horizontal-padding);
    }
  }

  .card {
    border-radius: 2.4rem;
    padding: 5.6rem;
    position: relative;
    // word-break: break-all;
    word-break: break-word;

    // MEDIA QUERY
    @media only screen and (max-width: $break-3) {
      padding: 5rem;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-9) {
      padding: 3.6rem;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-12) {
      padding: 3rem;
    }

    &--1 {
      background-color: $secondary-color;
      color: $color-text--var-2;

      span {
        font-weight: 600;
      }
    }

    &--2 {
      .paragraph {
        // margin-bottom: 3.2rem;

        &:nth-child(2),
        &:nth-child(5) {
          font-weight: 700;
          text-decoration: underline;
        }

        &:nth-child(3),
        &:nth-child(6) {
          margin-bottom: 1.2rem;
        }
      }

      .btn-2:first-of-type {
        margin-bottom: 4.8rem;
      }
    }

    &--3 {
      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        grid-row: 4;
      }

      span {
        font-weight: 600;
      }

      .bus {
        font-weight: 600;
        margin-left: 2rem;
      }

      .paragraph {
        margin-bottom: 3.2rem;
      }
    }

    &--4 {
      // border-radius: 2.4rem;
      background-color: $primary-color;
      color: $color-text--var-2;

      span {
        font-weight: 700;
      }

      .btn-2 {
        margin-top: 3.2rem;

        &__icon {
          fill: $color-text--var-2;

          &-facebook {
            height: 1.6rem;
            width: 1.6rem;
          }

          &-instagram {
            height: 2rem;
            width: 2rem;
            max-width: 2rem;
          }
        }

        &__icon-bg {
          border: 1px solid $color-text--var-2;
        }
      }
    }

    &--5 {
      background-color: $secondary-color;
      color: $color-text--var-2;

      .underline {
        text-decoration: underline;
      }

      .document {
        margin-left: 2rem;
      }
    }

    .star {
      fill: $color-text--var-2;
      width: 4rem;
      height: 4rem;
      position: absolute;
      top: 1.2rem;
      left: 1.2rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-9) {
        top: 0;
        left: 0;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-12) {
        width: 3.2rem;
        height: 3.2rem;
      }
    }
  }

  // .inscription {
  //   grid-column: 1 / 3;
  //   margin-top: 5.6rem;

  //   // MEDIA QUERY
  //   @media only screen and (max-width: $break-4) {
  //     grid-column: 1;
  //   }

  //   // MEDIA QUERY
  //   @media only screen and (max-width: $break-8) {
  //     margin-top: 0;
  //   }

  //   .heading-primary {
  //     margin-bottom: 0;
  //     margin-left: 3.6rem;
  //     margin-right: 3.6rem;

  //     // MEDIA QUERY
  //     @media only screen and (max-width: $break-8) {
  //       margin-left: 0;
  //       margin-right: 0;
  //       margin-top: 2.4rem;
  //       margin-bottom: 2.4rem;
  //     }
  //   }

  //   &__title {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     margin-bottom: 4.8rem;

  //     // MEDIA QUERY
  //     @media only screen and (max-width: $break-8) {
  //       flex-direction: column;
  //     }
  //   }

  //   // .paragraph {
  //   //   padding-left: 5.6rem;
  //   //   padding-right: 5.6rem;

  //   //   // MEDIA QUERY
  //   //   @media only screen and (max-width: $break-3) {
  //   //     padding: 0 5rem;
  //   //   }

  //   //   // MEDIA QUERY
  //   //   @media only screen and (max-width: $break-9) {
  //   //     padding: 0 3.6rem;
  //   //   }

  //   //   // MEDIA QUERY
  //   //   @media only screen and (max-width: $break-12) {
  //   //     padding: 0 3rem;
  //   //   }
  //   // }

  //   .document {
  //     margin-left: 2rem;
  //   }

  //   .star {
  //     fill: $color-text--var-2;
  //     width: 4rem;
  //     height: 4rem;
  //     flex-shrink: 0;

  //     // MEDIA QUERY
  //     @media only screen and (max-width: $break-9) {
  //       top: 0;
  //       left: 0;
  //     }

  //     // MEDIA QUERY
  //     @media only screen and (max-width: $break-12) {
  //       width: 3.2rem;
  //       height: 3.2rem;
  //     }
  //   }
  // }
}

//######################################################################
//######################################################################

//                          FOOTER

//######################################################################
//######################################################################
.footer {
  @extend %row;
  background-color: $color-grey--light;

  .footer-content {
    @extend %container;
    padding: 3.2rem 0;
    text-align: center;
  }

  .paragraph {
    display: flex;
    justify-content: center;

    // MEDIA QUERY
    @media only screen and (max-width: $break-6) {
      flex-direction: column;
    }

    & span:not(:last-child) {
      margin-right: 3rem;
    }

    & span:nth-child(even) {
      &::after {
        content: "\007C";

        // MEDIA QUERY
        @media only screen and (max-width: $break-6) {
          content: "\2022";
        }
      }
    }
  }
}
