//######################################################################
//######################################################################

//                          COLORS

//######################################################################
//######################################################################

$primary-color: hsl(251, 80%, 96%);
$secondary-color: hsl(168, 76%, 91%);
// $secondary-color: hsl(143, 74%, 91%);
$color-text: #222222;
$color-text--var-2: #3500fa;
$body-color: #fbfbfb;

$secondary-color--var-1: hsl(168, 70%, 75%);
$secondary-color--var-2: hsl(168, 76%, 86%);
$primary-color--var-1: hsl(251, 80%, 88%);
$tertiary-color: #fcf7f0;

$color-squares: #00ebbf;
$color-grey: #e6e6e6;
$color-grey--light: #f5f5f7;

//######################################################################
//######################################################################

//                          VARIABLES

//######################################################################
//######################################################################
// SASS VARIABLES

//can't declare width into a css variable and use it in media query, it doesn't work. You have to use sass variable.
$break-1: 80em; // 1280px
$break-2: 73.125em; // 1170px
$break-3: 64em; // 1024px
$break-4: 60em; // 960px
$break-5: 51.25em; // 820px
$break-6: 48.75em; // 780px
$break-7: 38.75em; // 620px
$break-8: 36.25em; // 580px
$break-9: 32.5em; // 520px
$break-10: 30em; // 480px
$break-11: 27.5em; // 440px
$break-12: 25.625em; // 410px
$break-13: 23.75em; // 380px

// $section-padding-top: 7.2rem;

:root {
  --body-horizontal-padding: 4vw;
  --section-padding-top: 7.2rem;

  // MEDIA QUERY
  @media only screen and (max-width: $break-3) {
    --body-horizontal-padding: 3vw;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-8) {
    --section-padding-top: 5.6rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-11) {
    --body-horizontal-padding: 4vw;
    --section-padding-top: 4.4rem;
  }
}

//######################################################################
//######################################################################

//                          FONTS

//######################################################################
//######################################################################

@font-face {
  font-family: "ranade";
  src: url("../fonts/ranade/Ranade-Bold.woff2") format("woff2"),
    url("../fonts/ranade/Ranade-Bold.woff") format("woff"),
    url("../fonts/ranade/Ranade-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ranade";
  src: url("../fonts/ranade/Ranade-MediumItalic.woff2") format("woff2"),
    url("../fonts/ranade/Ranade-MediumItalic.woff") format("woff"),
    url("../fonts/ranade/Ranade-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "ranade";
  src: url("../fonts/ranade/Ranade-Light.woff2") format("woff2"),
    url("../fonts/ranade/Ranade-Light.woff") format("woff"),
    url("../fonts/ranade/Ranade-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ranade";
  src: url("../fonts/ranade/Ranade-Regular.woff2") format("woff2"),
    url("../fonts/ranade/Ranade-Regular.woff") format("woff"),
    url("../fonts/ranade/Ranade-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ranade";
  src: url("../fonts/ranade/Ranade-BoldItalic.woff2") format("woff2"),
    url("../fonts/ranade/Ranade-BoldItalic.woff") format("woff"),
    url("../fonts/ranade/Ranade-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "barlow";
  src: url("../fonts/barlow/barlow-medium-webfont.woff2") format("woff2"),
    url("../fonts/barlow/barlow-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "barlow";
  src: url("../fonts/barlow/barlow-bold-webfont.woff2") format("woff2"),
    url("../fonts/barlow/barlow-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "barlow";
  src: url("../fonts/barlow/barlow-bolditalic-webfont.woff2") format("woff2"),
    url("../fonts/barlow/barlow-bolditalic-webfont.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "barlow";
  src: url("../fonts/barlow/barlow-regular-webfont.woff2") format("woff2"),
    url("../fonts/barlow/barlow-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "barlow";
  src: url("../fonts/barlow/barlow-light-webfont.woff2") format("woff2"),
    url("../fonts/barlow/barlow-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "barlow";
  src: url("../fonts/barlow/barlow-semibold-webfont.woff2") format("woff2"),
    url("../fonts/barlow/barlow-semibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "barlow";
  src: url("../fonts/barlow/barlow-italic-webfont.woff2") format("woff2"),
    url("../fonts/barlow/barlow-italic-webfont.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

//######################################################################
//######################################################################

//                          TYPOS

//######################################################################
//######################################################################

%title {
  font-family: "ranade";
  font-weight: 700;
  text-rendering: geometricPrecision;
}

%text {
  font-family: "barlow";
  font-weight: 400;
  text-rendering: geometricPrecision;
  font-size: 1.8rem;

  // MEDIA QUERY
  @media only screen and (max-width: $break-3) {
    font-size: 1.7rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-4) {
    font-size: 1.6rem;
  }
}

%btn-2 {
  font-family: "barlow";
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 1.9rem;
  text-rendering: geometricPrecision;
  font-size: 1.7rem;

  // MEDIA QUERY
  @media only screen and (max-width: $break-3) {
    font-size: 1.6rem;
  }
}

%legende {
  font-family: barlow;
  font-weight: 600;
  font-size: 1.4rem;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #898989;
}

.heading-primary {
  @extend %title;
  font-size: 4.8rem;
  line-height: 130%;
  letter-spacing: -2px;

  // ATTENTION a enlever de la typo
  // ATTENTION a ajouter les geometricPrecision dans toutes les fonts ailleurs
  margin-bottom: 3.2rem;

  // MEDIA QUERY
  @media only screen and (max-width: $break-2) {
    font-size: 4rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-4) {
    font-size: 3.6rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-11) {
    font-size: 3.4rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-13) {
    // font-size: 3.2rem;
  }
}

.section-title {
  @extend %title, %container;
  font-size: 6.4rem;
  // line-height: 6.4rem;
  line-height: 1;
  letter-spacing: -2px;
  // text-align: center;
  word-break: break-word;
  padding-bottom: 3rem;
  border-bottom: 2px solid $color-text;
  display: flex;
  align-items: start;

  // MEDIA QUERY
  @media only screen and (max-width: $break-2) {
    font-size: 5.6rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-4) {
    // font-size: 5rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-5) {
    // margin-bottom: 6rem;
    font-size: 5rem;
    line-height: 6rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-10) {
    // text-align: left;
    font-size: 4.6rem;
    padding-bottom: 2.4rem;
    line-height: 5.4rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-11) {
    font-size: 4.2rem;
    line-height: 5rem;
    padding-bottom: 2rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-12) {
    font-size: 4rem;
    line-height: 4.6rem;
    padding-bottom: 1.8rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-13) {
    font-size: 3.8rem;
    line-height: 4.8rem;
  }

  span {
    display: inline-block;
    // width: 100%;
  }

  &::before {
    content: "";
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 1px solid $color-text;
    margin-right: 3.2rem;
    display: inline-block;
    flex-shrink: 0;
    margin-top: 2.4rem;

    // MEDIA QUERY
    @media only screen and (max-width: $break-2) {
      margin-top: 2rem;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-5) {
      margin-top: 1.6rem;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-10) {
      width: 2.6rem;
      height: 2.6rem;
      margin-right: 2.6rem;
      margin-top: 1.5rem;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-12) {
      width: 2.2rem;
      height: 2.2rem;
      margin-right: 2.4rem;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-13) {
      margin-right: 2rem;
      margin-top: 1.4rem;
    }
  }

  // &::after {
  //   content: "";
  //   display: block;
  //   // width: 5rem;
  //   width: 100%;
  //   height: 2px;
  //   margin: 3rem 0;
  //   background-color: #222222;

  //   // MEDIA QUERY
  //   @media only screen and (max-width: $break-6) {
  //     width: 6rem;
  //   }

  //   // MEDIA QUERY
  //   @media only screen and (max-width: $break-11) {
  //     width: 5rem;
  //   }
  // }

  // &::before {
  //   content: "";
  //   display: block;
  //   width: 1rem;
  //   height: 1rem;
  //   border-radius: 50%;
  //   background-color: #222222;
  //   margin-right: 3.2rem;
  //   margin-bottom: 1rem;
  // }
}

.paragraph {
  @extend %text;
  letter-spacing: 1px;
  line-height: 180%;

  // MEDIA QUERY
  @media only screen and (max-width: $break-3) {
    // letter-spacing: 0.8px;
  }
}

.coupdepouce {
  font-weight: 700;
  font-style: italic;
}

.quote {
  font-family: "ranade";
  font-weight: 300;
  font-size: 3.6rem;
  letter-spacing: -0.5px;
  line-height: 130%;
  text-align: center;
  text-rendering: geometricPrecision;

  // MEDIA QUERY
  @media only screen and (max-width: $break-3) {
    font-size: 3rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-4) {
    font-size: 2.8rem;
  }

  .coupdepouce {
    font-weight: 500;
  }
}

.bold-allcaps {
  font-weight: 700;
  text-transform: uppercase;
}

//######################################################################
//######################################################################

//                          RESET

//######################################################################
//######################################################################

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px   10 / 16 = 62.5%
  scroll-behavior: smooth;
  color: $color-text;
}

body {
  &::-webkit-scrollbar {
    width: 0 !important;
  }

  background-color: $body-color;
}

a {
  text-decoration: none;
  color: currentColor;
}

ul {
  list-style: none;
}

picture {
  display: block;
}

img {
  display: block;
  border-radius: 0.8rem;
}

input {
  outline: none;
  border: none;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

//######################################################################
//######################################################################

//                          UTILITIES

//######################################################################
//######################################################################

%section {
  padding-top: var(--section-padding-top);
}

%row {
  padding: 0 var(--body-horizontal-padding);
}

%container {
  max-width: 128rem;
  margin: 0 auto;
}

%two-cols {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 2.4rem;
  align-items: center;
}

%ct-centered-it-start {
  display: grid;
  justify-content: center;
  justify-items: start;
  align-items: start;
  align-content: start;
}

// TODO : Put animation
// TODO : Propose 3 colors palettes
// TODO : Prosose gradient with textures
// TODO : Make a picture carrousel
// TODO : Add decorations
// TODO : Mettre à jour Figma
// TODO : Check accessibility
