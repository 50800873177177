//######################################################################
//######################################################################

//                          REUSABLE COMPONENTS

//######################################################################
//######################################################################
.section-title__wrapper {
  @extend %row;
  // display: grid;
  // justify-content: center;
  // justify-items: start;
  // padding: 0 var(--body-horizontal-padding);
  margin-bottom: 15rem;

  // MEDIA QUERY
  @media only screen and (max-width: $break-4) {
    margin-bottom: 11rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-5) {
    margin-bottom: 9rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-11) {
    margin-bottom: 7rem;
  }
}

.btn {
  @extend %text;
  line-height: 1.6rem;
  letter-spacing: 0.3px;

  display: inline-flex;
  align-items: center;
  column-gap: 1.6rem;

  padding: 1.6rem 2.4rem;
  border-radius: 7rem;
  border: 1px solid currentColor;
  transition: all 0.3s ease-in-out;

  @media (hover: hover) {
    &:hover {
      background-color: $secondary-color--var-1;
      transform: translateY(-4px);
    }
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-3) {
    font-weight: 500;
  }

  span {
    margin-bottom: 2px;
    margin-top: -2px;
  }

  &__icon {
    height: 1.2rem;
    width: 1.4rem;
    fill: currentColor;
  }
}

.btn-2 {
  @extend %btn-2;

  display: flex;
  align-items: center;
  column-gap: 1rem;

  &__icon {
    fill: $color-text;
    height: 1.2rem;
    width: 1.4rem;
    position: relative;

    &-facebook {
      // width: 1.6rem;
      // height: 1.6rem;
    }

    &-instagram {
    }
  }

  &__icon-bg {
    width: 3.8rem;
    height: 3.8rem;
    border-radius: 50%;
    border: 1px solid $color-text;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    transition: all 0.3s ease-in-out;
  }

  @media (hover: hover) {
    &:hover &__icon-bg {
      background-color: $secondary-color--var-1;
    }
  }
}

.img__text {
  @extend %text;

  line-height: 150%;
  margin-top: 0.8rem;

  div:nth-child(1) {
    letter-spacing: 1px;
  }

  div:nth-child(2) {
    letter-spacing: 0;
    font-size: 1.5rem;
    font-style: italic;
    display: flex;
    // align-items: center;

    column-gap: 0.6rem;

    &::before {
      content: "";
      width: 1.2rem;
      height: 1px;
      background-color: $color-text;
      transform: translateY(2px);
      margin-top: 1.1rem;
    }
  }
}

.row-type--1 {
  @extend %row;
  margin-top: 14.4rem;

  .row__container {
    @extend %container, %two-cols;
    align-items: end;

    // MEDIA QUERY
    @media only screen and (max-width: $break-4) {
      row-gap: 6rem;
      align-items: start;
    }
  }

  .paragraph {
    margin-bottom: 2.4rem;
  }

  .text-content {
    margin-bottom: 7.2rem;
    padding-left: min(calc(11.4rem * 2), calc(7vw * 2));

    // MEDIA QUERY
    @media only screen and (max-width: $break-4) {
      padding: 0;
      padding-right: 7vw;
      // margin-bottom: 16rem;
      margin-bottom: 0;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-5) {
      padding: 0 4vw;
      width: 70%;
      grid-column: 1 / 3;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-7) {
      width: 75%;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-11) {
      width: 80%;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-12) {
      width: 100%;
    }
  }

  .img-container:nth-child(2) {
    justify-self: end;
    transform: translateY(13rem);
    width: 90%;
    display: grid;
    justify-items: end;

    // MEDIA QUERY
    @media only screen and (max-width: $break-4) {
      width: 100%;

      justify-self: start;
      align-self: end;
      // margin-top: 27rem;
      transform: translateY(0);
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-5) {
      display: none;
    }

    img {
      // display: none;
      width: 100%;
      height: auto;
      aspect-ratio: 1;
      object-fit: cover;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        box-shadow: none;
      }
    }

    .img__text {
      display: flex;
      flex-direction: column;
      align-items: end;

      div:last-child {
        &::before {
          width: 0;
        }

        &::after {
          content: "";
          width: 1.2rem;
          height: 1px;
          background-color: $color-text;
          transform: translateY(2px);
          margin-top: 1.1rem;
        }
      }
    }
  }

  .img-container:nth-child(3) {
    width: 125%;

    // MEDIA QUERY
    @media only screen and (max-width: $break-4) {
      width: 170%;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-5) {
      grid-column: 1 / 3;
      width: 100%;
    }

    img {
      // display: none;
      width: 100%;
      height: auto;
      aspect-ratio: 1.77;
      object-fit: cover;

      // MEDIA QUERY
      @media only screen and (max-width: $break-10) {
        // width: calc(100% + 6vw);
        // margin-left: calc(var(--body-horizontal-padding) * -1);
      }
    }
  }
}

.collecte__row-7.row-type--1 {
  .text-content {
    // MEDIA QUERY
    @media only screen and (max-width: $break-4) {
      padding-left: 7vw;
    }
  }

  .img-container:nth-child(2) {
    margin-top: 0;
    align-self: end;
  }
}

//######################################################################
//######################################################################

//                          BANNER - CSS ONLY

//######################################################################
//######################################################################
.banner-coupdepouce {
  max-width: 100vw;
  margin: 20rem 0;
  overflow: hidden;

  // MEDIA QUERY
  @media only screen and (max-width: $break-9) {
    margin: 16rem 0;
  }

  .banner__content {
    display: grid;
    grid-template-columns: repeat(8, max-content);
    column-gap: 18rem;
    align-items: center;
    width: fit-content;

    animation: banner 16s linear infinite;
  }

  .text-wrapper {
    display: flex;
    font-size: 26rem;
    line-height: 26rem;

    // MEDIA QUERY
    @media only screen and (max-width: $break-8) {
      font-size: 16rem;
      padding: 2rem 0;
    }

    &--1 {
      font-family: "ranade";
      font-weight: 700;
      line-height: 130%;
      letter-spacing: -0.6rem;
      color: #63f1dc;
    }

    &--2 {
      font-family: Barlow;
      font-style: normal;
      font-weight: 900;
      line-height: 130%;
      color: hsl(254, 89%, 94%);
    }
  }

  .separator {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background-color: #e3e3e3;
  }

  // .text-1 {
  //   color: rgba(0, 235, 191, 0.7);
  //   // font-size: 20rem;
  //   font-family: Barlow;
  //   font-style: normal;
  //   font-weight: 900;
  //   line-height: 90%;
  //   width: 61rem;

  //   // MEDIA QUERY
  //   @media only screen and (max-width: $break-8) {
  //     width: 47rem;
  //   }
  // }

  // .text-2 {
  //   color: #fde2fa;
  //   // font-size: 20rem;
  //   font-family: Barlow;
  //   font-style: normal;
  //   font-weight: 900;
  //   line-height: 90%;
  //   width: 61rem;
  //   margin: 6.5rem 0 0 -35rem;
  //   // transform: translate(-35rem, 6.5rem) rotate(180deg);
  //   transform: rotate(180deg);

  //   // MEDIA QUERY
  //   @media only screen and (max-width: $break-8) {
  //     width: 47rem;
  //     margin: 6rem 0 0 -24rem;
  //   }
  // }

  // .text-wrapper--2 {
  //   .text-1 {
  //     color: rgba(16, 12, 228, 0.4);
  //   }
  //   .text-2 {
  //     color: #fff6ea;
  //   }
  // }

  // .separator {
  //   flex-shrink: 0;
  //   display: flex;
  //   flex-direction: column;
  //   // justify-content: center;
  //   justify-content: space-between;
  //   position: relative;

  //   &__img {
  //     // width: 20rem;
  //     // height: 20rem;
  //     border-radius: 2.4rem;
  //     aspect-ratio: 1 / 1;
  //     border-radius: 2.4rem;
  //     background-image: url("../img/banner-img-1.jpg");
  //     background-size: cover;
  //     transform: rotate(8deg);
  //   }

  //   &--2 .separator__img {
  //     background-image: url("../img/banner-img-2.jpg");
  //     transform: rotate(-8deg);
  //   }

  //   &__text {
  //     // position: absolute;
  //     color: #000;
  //     font-size: 2rem;
  //     font-family: Barlow;
  //     font-style: normal;
  //     font-weight: 700;
  //     line-height: 90%;
  //     display: flex;
  //     align-items: center;
  //     column-gap: 1.6rem;
  //     // text-transform: uppercase;
  //     letter-spacing: 0.5px;

  //     &--1 {
  //       transform-origin: top left;
  //       transform: translateX(100%) rotate(90deg);
  //       top: 0;
  //       right: 0;
  //     }

  //     &--2 {
  //       transform-origin: top left;
  //       transform: translateY(100%) rotate(-90deg);
  //       bottom: 0;
  //       left: 0;
  //     }

  //     &::before {
  //       content: "";
  //       display: block;
  //       width: 0.8rem;
  //       height: 0.8rem;
  //       border-radius: 50%;
  //       background-color: #d9d9d9;
  //       margin-top: 1px;
  //     }
  //   }
  // }
}

@keyframes banner {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(calc(-50% - 9rem));
  }
}

//######################################################################
//######################################################################

//                          OBJECTIFS

//######################################################################
//######################################################################

.about__objectifs {
  @extend %row;

  .heading-wrapper {
    @extend %container;

    margin-bottom: 12rem;
    display: flex;
    justify-content: center;

    // MEDIA QUERY
    @media only screen and (max-width: $break-12) {
      margin-bottom: 8rem;
    }
  }

  .heading-primary {
    display: flex;
    align-items: center;

    &::before,
    &::after {
      content: "";
      display: inline-block;
      width: 3rem;
      height: 2px;
      background-color: $color-text;
      margin-top: 1rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-11) {
        width: 2rem;
      }
    }

    &::before {
      margin-right: 2rem;
    }

    &::after {
      margin-left: 2rem;
    }
  }

  .row__container {
    @extend %container, %two-cols;

    align-items: center;
    // justify-items: center;
    row-gap: 12rem;

    // MEDIA QUERY
    @media only screen and (max-width: $break-5) {
      // column-gap: 4rem;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, min-content);
      row-gap: 3.8rem;
      column-gap: 0;
      justify-items: center;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-11) {
      row-gap: 2.4rem;
    }
  }

  h3 {
    color: $color-text;
    font-family: Barlow;
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 180%;
    letter-spacing: 0.1rem;
  }

  p {
    margin-top: 0.6rem;
    padding-bottom: 2.4rem;
    border-bottom: 2px solid $color-text;

    span {
      font-style: italic;
      font-weight: 700;
    }
  }

  .right {
    margin-bottom: 2rem;

    // MEDIA QUERY
    @media only screen and (max-width: $break-5) {
      width: 60%;
      margin-bottom: 10rem;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-8) {
      width: 80%;
      margin-bottom: 10rem;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-11) {
      width: 90%;
      margin-bottom: 10rem;
    }
  }

  .left {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .img-01 {
    width: 90%;
    height: auto;
    // object-fit: cover;
    aspect-ratio: 1.88;

    // MEDIA QUERY
    @media only screen and (max-width: $break-5) {
      width: 100%;
      aspect-ratio: 1.88;
    }
  }

  .img-02 {
    width: 70%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 1.2;

    // MEDIA QUERY
    @media only screen and (max-width: $break-5) {
      width: 80%;
      aspect-ratio: 1.2;
    }
  }

  .img-03 {
    width: 70%;
    height: auto;
    // object-fit: cover;
    aspect-ratio: 1.42;

    // MEDIA QUERY
    @media only screen and (max-width: $break-5) {
      width: 92%;
      aspect-ratio: 1.42;
    }
  }
}

//######################################################################
//######################################################################

//                          ANIMATIONS

//######################################################################
//######################################################################
